import React from 'react'

import { MyBonuses } from 'widgets/my-bonuses'
import { LayoutProfile } from 'layouts/profile'
import { LayoutPrimary } from 'layouts/primary'

export const PageMyBonuses = () => (
  <LayoutPrimary>
    <LayoutProfile
      pageTitle={{ text: 'my bonuses', titleSurfaceColor: 'surface-base' }}
    >
      <MyBonuses />
    </LayoutProfile>
  </LayoutPrimary>
)

export default PageMyBonuses
