import React from 'react'

import { LayoutPrimary } from 'layouts/primary'
import { LayoutDocument } from 'layouts/document'

import { ResponsibleGamblingPage } from './ResponsibleGamblingPage'

export const PageResponsibleGambling = () => (
  <LayoutPrimary>
    <LayoutDocument pageTitle={{ text: 'responsible gambling' }} isHiddenFooter>
      <ResponsibleGamblingPage />
    </LayoutDocument>
  </LayoutPrimary>
)

export default PageResponsibleGambling
