import React from 'react'

import { LayoutProfile } from 'layouts/profile'
import { LayoutPrimary } from 'layouts/primary'

import { Promotions } from './promotions'

export const PagePromotions = () => (
  <LayoutPrimary>
    <LayoutProfile
      pageTitle={{ text: 'promotions', titleSurfaceColor: 'surface-base' }}
    >
      <Promotions />
    </LayoutProfile>
  </LayoutPrimary>
)

export default PagePromotions
