import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { selectConfig } from 'astra-core/containers/ConfigProvider'
import { useSelector } from 'react-redux'
import { selectAccountUser } from 'astra-core/containers/AccountProvider'

import { ProfileNavMenu } from 'features/profile-nav-menu'
import { BalanceControls } from 'features/balance-controls'
import { IconExit } from 'shared/ui/Icon/General/IconExit'

import { getLogoutUrl } from './utils'
import {
  StyledProfileMenuWrapper,
  StyledNavAndExitContainer,
  StyledProfileLogin,
  StyledProfileUserTitle,
  StyledProfileBalanceContainer,
  StyledProfilePhoneContainer,
  StyledButtonExit
} from './profile-menu.styled'

export const ProfileMenu = memo(() => {
  const [t] = useTranslation()

  const config = useSelector(selectConfig)
  const user = useSelector(selectAccountUser)
  const userLogin = user?.login

  return user ? (
    <StyledProfileMenuWrapper>
      <StyledProfilePhoneContainer>
        <StyledProfileUserTitle>{t('user')}</StyledProfileUserTitle>
        <StyledProfileLogin>{userLogin}</StyledProfileLogin>
      </StyledProfilePhoneContainer>
      <StyledProfileBalanceContainer>
        <BalanceControls />
      </StyledProfileBalanceContainer>
      <StyledNavAndExitContainer>
        <ProfileNavMenu isAuthorized />
        <form action={getLogoutUrl(config)} method="POST">
          <StyledButtonExit view="secondary-extra-large">
            <IconExit />
            {t('exit')}
          </StyledButtonExit>
        </form>
      </StyledNavAndExitContainer>
    </StyledProfileMenuWrapper>
  ) : (
    <StyledProfileMenuWrapper>
      <StyledNavAndExitContainer>
        <ProfileNavMenu />
      </StyledNavAndExitContainer>
    </StyledProfileMenuWrapper>
  )
})
