import React from 'react'

import { LayoutDocument } from 'layouts/document'
import { LayoutPrimary } from 'layouts/primary'

import { AboutPage } from './AboutPage'

export const PageAbout = () => (
  <LayoutPrimary>
    <LayoutDocument pageTitle={{ text: 'olimpbet bookmaker' }}>
      <AboutPage />
    </LayoutDocument>
  </LayoutPrimary>
)

export default PageAbout
