import { ContentLoader } from '../../../../shared/ui/content-loader/content-loader'

import * as S from './probs.styled'

export const ProbsLoader = () => {
  return (
    <S.EventCardProbabilitiesWrapper>
      <ContentLoader borderRadius={4} height={32} width="100%" />
      <ContentLoader borderRadius={4} height={32} width="100%" />
      <ContentLoader borderRadius={4} height={32} width="100%" />
    </S.EventCardProbabilitiesWrapper>
  )
}
