import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Button } from 'shared/ui/Button'
import { ERoutes } from 'shared/types/routes'

import {
  StyledErrorContainer,
  StyledErrorDescr,
  StyledErrorTitle,
  StyledIconError404
} from './Error404Page.styled'

export const Error404Page = memo(() => {
  const [t] = useTranslation()

  return (
    <StyledErrorContainer>
      <StyledIconError404 size={64} />
      <StyledErrorTitle>{t('error 404')}</StyledErrorTitle>
      <StyledErrorDescr>{t('sorry, page was not found')}</StyledErrorDescr>
      <Button as={Link} to={ERoutes.Root} view="secondary-extra-large">
        {t('go back to the main page')}
      </Button>
    </StyledErrorContainer>
  )
})
