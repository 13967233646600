import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectAccountUser } from 'astra-core/containers/AccountProvider'

import { NavTabs } from 'shared/ui/nav-tabs'
import { FooterOfPage } from 'widgets/footer-of-page'
import { Scrollable } from 'shared/ui/scrollable'
import { BreakTab, InfoTab, LimitTab } from 'features/responsible-gambling'

import {
  NavTabsWrapper,
  StyledTabLayout,
  StyledTabScrollable
} from './tabs.styled'
import { SECTION_SELECT_OPTIONS } from './lib/constants'
import { EResponsibleGambling } from './lib'

const TabsComponents = {
  [EResponsibleGambling.INFO]: <InfoTab />,
  [EResponsibleGambling.LIMIT]: <LimitTab />,
  [EResponsibleGambling.BREAK]: <BreakTab />
}

export const ResponsibleGamblingTabs = () => {
  const [activeTab, setActiveTab] = useState(EResponsibleGambling.INFO)

  const handleClick = useCallback((value) => setActiveTab(value), [])
  const user = useSelector(selectAccountUser)

  return (
    <>
      {user && (
        <NavTabsWrapper>
          <NavTabs<true>
            items={SECTION_SELECT_OPTIONS}
            value={activeTab as string}
            isControlled
            onChange={handleClick}
          />
        </NavTabsWrapper>
      )}

      <StyledTabScrollable isUser={!!user}>
        <Scrollable>
          <StyledTabLayout>
            {TabsComponents[activeTab]}
            <FooterOfPage />
          </StyledTabLayout>
        </Scrollable>
      </StyledTabScrollable>
    </>
  )
}
