import React, { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'

import { NotificationsPanel } from 'widgets/notifications-panel'
import { ProfileMenu } from 'widgets/profile-menu'
import { FooterOfPage } from 'widgets/footer-of-page'
import {
  ColumnLayout,
  ColumnLayoutBaseProps,
  ColumnLayoutTitle,
  ColumnTypes
} from 'shared/lib/layout'

import * as S from './document.styled'

export const Document: FC<
  Required<Pick<ColumnLayoutBaseProps, 'pageTitle' | 'children'>> & {
    isHiddenFooter?: true
  }
> = memo(({ pageTitle, children, isHiddenFooter }) => {
  const [t] = useTranslation()

  return (
    <ColumnLayout leftGridArea="profile-menu" type={ColumnTypes.TWO_COLUMN}>
      <ProfileMenu />

      <NotificationsPanel />

      <S.Content>
        {pageTitle?.text && (
          <ColumnLayoutTitle {...pageTitle}>
            {t(pageTitle.text)}
          </ColumnLayoutTitle>
        )}

        {children}

        {!isHiddenFooter && <FooterOfPage />}
      </S.Content>
    </ColumnLayout>
  )
})
