import React, { useEffect } from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'

import {
  NOTIFICATIONS_LINKS,
  PAGINATION_SETTINGS
} from 'pages/page-notification/notifications/notifications.constants'
import { NavTabs } from 'shared/ui/nav-tabs'
import { ERoutes } from 'shared/types/routes'

import {
  StyledNotifications,
  StyledDataList,
  NotificationsTabsWrapper,
  NotificationContent
} from './notifications.styled'
import { NotificationsPageContainer } from './components/page-container'

export const Notifications = () => {
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    if (location.pathname === ERoutes.Notifications) {
      history.push(ERoutes.NewNotifications)
    }
  }, [history, location.pathname])

  return (
    <StyledNotifications>
      <NotificationsTabsWrapper>
        <NavTabs isControlled={false} items={NOTIFICATIONS_LINKS} />
      </NotificationsTabsWrapper>

      <NotificationContent>
        <StyledDataList pagination={PAGINATION_SETTINGS} isHidePreloader>
          <Switch>
            <Route
              component={NotificationsPageContainer}
              path={ERoutes.NewNotifications}
            />
            <Route
              component={NotificationsPageContainer}
              path={ERoutes.ReadNotifications}
            />
          </Switch>
        </StyledDataList>
      </NotificationContent>
    </StyledNotifications>
  )
}
