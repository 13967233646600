import React from 'react'

import { LayoutPrimary } from 'layouts/primary'
import { LayoutExternalFeatureProvider } from 'layouts/external-feature-provider'

import { Statistics } from './Statistics/Statistics'

export const PageStatistics = () => (
  <LayoutPrimary>
    <LayoutExternalFeatureProvider>
      <Statistics />
    </LayoutExternalFeatureProvider>
  </LayoutPrimary>
)

export default PageStatistics
