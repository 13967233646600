import React from 'react'
import { useGameVendorSettings } from 'astra-core'
import { VirtualGameVendor } from 'betweb-openapi-axios'

import { AvailablePanel } from 'shared/ui/AvailablePanel'
import { LayoutGame } from 'layouts/game'
import { LayoutPrimary } from 'layouts/primary'

import { TurboGames } from './TurboGames'

export const PageGamesTurbo = () => {
  const isAvailable = useGameVendorSettings(VirtualGameVendor.TurboGames)

  return (
    <LayoutPrimary>
      <LayoutGame>
        {isAvailable ? <TurboGames /> : <AvailablePanel />}
      </LayoutGame>
    </LayoutPrimary>
  )
}

export default PageGamesTurbo
