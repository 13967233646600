import { FormikProvider, useFormik } from 'formik'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectAccountUser } from 'astra-core/containers/AccountProvider'

import { changePasswordApi } from 'entities/legacy-api/lib/change-password'
import { TextInputFormik } from 'features/formik/ui/text-input-formik'
import { PasswordValidation } from 'features/formik/ui/password-validation'
import { Button } from 'shared/ui/Button'
import {
  ENotificationContainerIds,
  usePopUpNotification
} from 'shared/lib/Toastify'

import { useShowPassword } from '../show-password'

import { CHANGE_PASSWORD_FORMIK_SCHEMA } from './change-password-formik-schema'
import {
  StyledContainer,
  ButtonsWrapper,
  FormWrapper,
  InputsWrapper,
  NewPasswordInputWrapper
} from './profile-change-password-modal.styled'

const { useChangePasswordMutation } = changePasswordApi

interface Values {
  oldPassword: string
  newPassword: string
}

interface ProfileChangePasswordModalProps {
  onClose: () => void
}

export const ProfileChangePasswordModal = ({
  onClose
}: ProfileChangePasswordModalProps) => {
  const [t] = useTranslation()
  const [changePassword, { isLoading }] = useChangePasswordMutation()
  const popupSuccess = usePopUpNotification({
    content: {
      children: () => t('password updated successfully')
    },
    options: {
      containerId: ENotificationContainerIds.SYSTEM,
      position: 'bottom-left',
      type: 'success',
      autoClose: 5000
    }
  })
  const user = useSelector(selectAccountUser)
  const { showPassword, ShowPasswordComponent } = useShowPassword()
  const {
    showPassword: showNewPassword,
    ShowPasswordComponent: ShowNewPasswordComponent
  } = useShowPassword()

  const bannedPasswords = useMemo(() => {
    if (!user) return []
    return [user.phone ?? '', user.login ?? '']
  }, [user])

  const form = useFormik<Values>({
    initialValues: {
      oldPassword: '',
      newPassword: ''
    },
    onSubmit: async (values) => {
      const result = await changePassword({
        lang_id: 0,
        passwr: values.oldPassword,
        new_passw: values.newPassword
      })

      if ('error' in result) {
        form.setFieldError('oldPassword', t('wrong old password'))
      } else {
        popupSuccess()
        onClose()
      }
    },
    validateOnChange: true,
    validationSchema: CHANGE_PASSWORD_FORMIK_SCHEMA(bannedPasswords)
  })

  return (
    <StyledContainer>
      <FormikProvider value={form}>
        <FormWrapper>
          <InputsWrapper>
            <TextInputFormik
              renderRight={
                form.getFieldMeta('oldPassword').value && ShowPasswordComponent
              }
              label={t('current password')}
              name="oldPassword"
              size="l"
              type={showPassword ? 'text' : 'password'}
              isAuthForm
            />
            <NewPasswordInputWrapper>
              <TextInputFormik
                renderRight={
                  form.getFieldMeta('newPassword').value &&
                  ShowNewPasswordComponent
                }
                label={t('renewed password')}
                name="newPassword"
                size="l"
                type={showNewPassword ? 'text' : 'password'}
                isAuthForm
                preShowErrors
              />
              <PasswordValidation
                bannedPasswords={bannedPasswords}
                name="newPassword"
              />
            </NewPasswordInputWrapper>
          </InputsWrapper>
          <ButtonsWrapper>
            <Button
              type="button"
              view="secondary-extra-large"
              onClick={onClose}
            >
              {t('to cancel')}
            </Button>
            <Button
              disabled={isLoading}
              isLoading={isLoading}
              type="submit"
              view="primary-extra-large"
              onClick={() => {
                form.validateField('oldPassword')
                form.validateField('newPassword')
              }}
            >
              {t('to save')}
            </Button>
          </ButtonsWrapper>
        </FormWrapper>
      </FormikProvider>
    </StyledContainer>
  )
}
