import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { Event, LogoEntityType, Sport } from 'betweb-openapi-axios'

import { mainPageContainerActions } from 'pages/page-main/MainPageContainer/slice'
import {
  selectTopEvents,
  selectTopEventsCurrentSportId,
  selectTopEventsSports,
  selectTopEventsLoaded
} from 'pages/page-main/MainPageContainer/selectors'
import { SportTabs } from 'features/sport-tabs'
import { Carousel } from 'shared/ui/Carousel'
import { usePrevious } from 'shared/lib/use-previous/usePrevious'
import { getLogos } from 'entities/logos/actions'
import { Skeleton } from 'shared/ui/skeleton'
import { SportTabsLoader } from 'features/sport-tabs/sport-tabs.loader'
import { TopEventsLoader } from 'pages/page-main/Main/components/top-events/top-events.loader'
import { EventCard } from 'features/event-card'
import { selectEntityTags, sortByEntityTags } from 'entities/event'

import {
  SLIDES_GAP,
  SLIDES_HEIGHT,
  SLIDES_MAX_WIDTH,
  SLIDES_MIN_WIDTH
} from './top-events.constants'
import {
  StyledEventsCarouselWrapper,
  StyledMainPageEventsHeader,
  StyledMainPageEventsHeaderWrapper,
  StyledMainPageEventsWrapper,
  StyledSportListWrapper
} from './top-events.styled'

export const TopEvents = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const events = useSelector(selectTopEvents)
  const sports = useSelector(selectTopEventsSports)
  const activeSport = useSelector(selectTopEventsCurrentSportId)
  const onSportChange = useCallback(
    (sportId: Sport['id']) => {
      dispatch(mainPageContainerActions.selectTopEventsSport({ sportId }))
    },
    [dispatch]
  )
  const isLoaded = useSelector(selectTopEventsLoaded)

  if (!events.length && isLoaded) {
    return null
  }

  return (
    <StyledMainPageEventsWrapper>
      <StyledMainPageEventsHeaderWrapper>
        <StyledMainPageEventsHeader>
          {t('top events')}
        </StyledMainPageEventsHeader>
      </StyledMainPageEventsHeaderWrapper>

      <StyledSportListWrapper>
        <Skeleton isLoading={!isLoaded} styledFallback={<SportTabsLoader />}>
          <SportTabs
            activeSport={activeSport}
            items={sports}
            onChange={onSportChange}
          />
        </Skeleton>
      </StyledSportListWrapper>
      <EventsCarousel events={events} />
    </StyledMainPageEventsWrapper>
  )
}

type EventsCarouselProps = {
  events: Event[]
}

const EventsCarousel: FC<EventsCarouselProps> = ({ events }) => {
  const dispatch = useDispatch()
  const prevEvents = usePrevious(events, true)
  const isLoaded = useSelector(selectTopEventsLoaded)
  const entityTags = useSelector(selectEntityTags)

  const sortedEventsByTags = useMemo(() => {
    const liveEvents = events.filter((e) => e.live)
    const lineEvents = events.filter((e) => !e.live)

    const sortedLive = sortByEntityTags(liveEvents, entityTags)
    const sortedLine = sortByEntityTags(lineEvents, entityTags)

    return [...sortedLive, ...sortedLine]
  }, [events, entityTags])

  useEffect(() => {
    if (prevEvents?.length !== events.length) {
      const competitorIds = events.reduce((acc, cur) => {
        acc.push(...cur.competitors.map((c) => c.id))
        return acc
      }, [] as number[])

      dispatch(
        getLogos({
          entityType: LogoEntityType.Competitor,
          ids: competitorIds
        })
      )
    }
  }, [dispatch, events, prevEvents?.length])

  return (
    <StyledEventsCarouselWrapper>
      <Skeleton isLoading={!isLoaded} styledFallback={<TopEventsLoader />}>
        <Carousel
          childHeight={SLIDES_HEIGHT}
          childMaxWidth={SLIDES_MAX_WIDTH}
          childMinWidth={SLIDES_MIN_WIDTH}
          leftOffset={0}
          rightOffset={0}
          slidesGap={SLIDES_GAP}
        >
          {sortedEventsByTags.map((event) => (
            <EventCard event={event} key={event.id} />
          ))}
        </Carousel>
      </Skeleton>
    </StyledEventsCarouselWrapper>
  )
}
