import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  selectFilterIsLiveResults,
  resultsProviderActions,
  selectResultsFilterDate
} from 'astra-core/containers/ResultsProvider'
import { useDispatch, useSelector } from 'react-redux'

import { SegmentedControl } from 'shared/ui/segmented-control'
import { isDateToday } from 'shared/lib/todayDate'

import { EResultMenuTabTypes } from '../../results.types'

export const ResultTabs = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const isLiveResults = useSelector(selectFilterIsLiveResults)
  const date = useSelector(selectResultsFilterDate)

  const onResetDatePicker = useCallback(() => {
    dispatch(resultsProviderActions.resetDatePickerRangeValues())
    dispatch(resultsProviderActions.fetchResults({ withLinkedEvents: false }))
  }, [dispatch])

  const currentTabResult = isLiveResults
    ? EResultMenuTabTypes.LIVE
    : EResultMenuTabTypes.COMPLETED

  const handleTabChange = useCallback(
    (value) => {
      if (value !== currentTabResult) {
        dispatch(resultsProviderActions.toggleIsLiveEventsFilter())
      }
      if (value === EResultMenuTabTypes.LIVE && !isDateToday(date)) {
        onResetDatePicker()
      }
    },
    [currentTabResult, date, dispatch, onResetDatePicker]
  )

  const items = useMemo(
    () => [
      {
        value: EResultMenuTabTypes.COMPLETED,
        label: t('completed')
      },
      {
        value: EResultMenuTabTypes.LIVE,
        label: t('live')
      }
    ],
    [t]
  )

  return (
    <SegmentedControl
      items={items}
      itemSize={28}
      value={currentTabResult}
      view="secondary"
      onChange={handleTabChange}
    />
  )
}
