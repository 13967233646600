import React from 'react'

import { LayoutPrimary } from 'layouts/primary'
import { LayoutError } from 'layouts/error'

import { UnderConstructionPage } from './under-construction-page'

export const PageUnderConstruction = () => (
  <LayoutPrimary>
    <LayoutError>
      <UnderConstructionPage />
    </LayoutError>
  </LayoutPrimary>
)

export default PageUnderConstruction
