import React, { FC } from 'react'
import { useLocation } from 'react-router-dom'
import { selectAccountUser } from 'astra-core/containers/AccountProvider'
import { useSelector } from 'react-redux'

import { useGetSportGamesQuery } from 'entities/legacy-api/lib/sport-games/sport-games.api'
import { LoaderSpinner } from 'shared/ui/LoaderSpinner'

import { IPageSportGamesParams } from '../page.types'

import { iframeStyles, iframeId } from './utils'
import { StyledLoaderPointsWrapper } from './SportGames.styled'

export const SportGames: FC = () => {
  const { state } = useLocation<IPageSportGamesParams>()
  const user = useSelector(selectAccountUser)

  const { data, isLoading } = useGetSportGamesQuery({
    platforma: 'NEWOLIMPBET',
    ...(user && { session: '__session__' }),
    ...(state?.productId && { product: state.productId })
  })

  return isLoading ? (
    <StyledLoaderPointsWrapper>
      <LoaderSpinner size={40} />
    </StyledLoaderPointsWrapper>
  ) : (
    <SportGamesIframe iframeSrc={data?.data.url} />
  )
}

const SportGamesIframe = ({ iframeSrc }) => {
  return <iframe src={iframeSrc} style={iframeStyles} title={iframeId} />
}
