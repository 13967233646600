import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import {
  selectBannerItems,
  selectBannersLoading
} from 'pages/page-main/MainPageContainer/selectors'
import { Carousel } from 'shared/ui/Carousel'
import { useCompleted } from 'hooks'
import { useHydrated } from 'shared/lib/hydrated/use-hydrated'
import { Skeleton } from 'shared/ui/skeleton'

import { StyledCarouselWrapper } from './Banners.styled'
import {
  AUTOPLAY_TIMEOUT,
  SLIDES_GAP,
  SLIDES_HEIGHT,
  SLIDES_MIN_WIDTH
} from './Banners.constants'
import { Banner } from './Banner'
import { BannersLoader } from './banners.loader'

export const Banners: FC = () => {
  const banners = useSelector(selectBannerItems)
  const loading = useSelector(selectBannersLoading)
  const isCompleted = useCompleted(!!loading)
  const hydrated = useHydrated()

  if (hydrated && !banners.length && isCompleted) {
    return null
  }

  const bannersExist = !!banners.length

  return (
    <StyledCarouselWrapper bannersExist={bannersExist}>
      <Skeleton isLoading={!isCompleted} styledFallback={<BannersLoader />}>
        <Carousel
          autoplayInterval={AUTOPLAY_TIMEOUT}
          childAspectRatio={359 / 147}
          childHeight={SLIDES_HEIGHT}
          childMinWidth={SLIDES_MIN_WIDTH}
          hideDots={!bannersExist}
          slidesGap={SLIDES_GAP}
          infinite
        >
          {banners.map((banner) => (
            <Banner banner={banner} key={banner.id} />
          ))}
        </Carousel>
      </Skeleton>
    </StyledCarouselWrapper>
  )
}
