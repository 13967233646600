import React from 'react'

import { LayoutProfile } from 'layouts/profile'
import { LayoutPrimary } from 'layouts/primary'

import { BetsHistoryPageContainer } from './BetsHistoryPageContainer'

export const PageBetsHistory = () => {
  return (
    <LayoutPrimary>
      <LayoutProfile pageTitle={{ text: 'history bets' }} isHeaderFilled>
        <BetsHistoryPageContainer />
      </LayoutProfile>
    </LayoutPrimary>
  )
}

export default PageBetsHistory
