import { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { notificationsApi, resetNotifications } from 'entities/legacy-api'
import { LoaderSpinner } from 'shared/ui/LoaderSpinner'

import {
  StyledNotificationsList,
  Notification,
  NotificationsEmpty
} from '../components'
import { NotificationsProps } from '../notifications.types'

const { useGetNotificationsQuery, useReadNotificationMutation } =
  notificationsApi

const INVALIDATION_INTERVAL = 60

export const NotificationsLegacy: FC<NotificationsProps> = ({ type }) => {
  const dispatch = useDispatch()
  const { data, isFetching } = useGetNotificationsQuery(
    { type },
    { refetchOnMountOrArgChange: INVALIDATION_INTERVAL }
  )
  const [readNotification, { isSuccess: isAnythingRead, reset: resetRead }] =
    useReadNotificationMutation()

  const handleRead = (id: number) => () => {
    if (type === 'notread') {
      readNotification({ id })
    }
  }

  useEffect(() => {
    if (isAnythingRead) {
      // Add timeout to prevent refetch of all notification queries at once
      setTimeout(() => {
        dispatch(resetNotifications())
        resetRead()
      })
    }
  }, [type]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      // Add timeout to prevent query refetch after all subscribed components unmounted
      setTimeout(() => {
        dispatch(resetNotifications())
      })
    }
  }, [dispatch])

  const notificationsList = data?.data || []
  const isEmpty = !notificationsList?.length

  if (isFetching) return <LoaderSpinner />

  if (isEmpty) return <NotificationsEmpty />

  return (
    <StyledNotificationsList>
      {notificationsList.map(
        ({ add_date: date, id: messageId, text, type }) => (
          <Notification
            date={date}
            key={messageId}
            text={text}
            type={type}
            onRead={handleRead(messageId)}
          />
        )
      )}
    </StyledNotificationsList>
  )
}
