import React from 'react'

import { LayoutPrimary } from 'layouts/primary'

import { Casino } from './Casino'
import * as S from './page.styled'

export const PageCasino = () => (
  <LayoutPrimary>
    <S.Page>
      <S.PageContent>
        <Casino />
      </S.PageContent>
    </S.Page>
  </LayoutPrimary>
)

export default PageCasino
