import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { IconLiveSmall } from '../../../../shared/ui/Icon/General/IconLiveSmall'
import { EEventStatisticsCodes } from '../../../../shared/lib/events/types'
import { useTimerCountdown } from '../../../../shared/lib/events/timer-countdown'

import { EventCardLiveContentProps } from './event-card-live-content.types'
import * as S from './event-card-live-content.styled'

export const EventCardLiveContent: FC<EventCardLiveContentProps> = ({
  event
}) => {
  const [t] = useTranslation()

  const { hasTime, value } = useTimerCountdown({ event })

  const score = event.statistics.find(
    (s) => s.code === EEventStatisticsCodes.Score
  )

  const eventScore = score?.value ?? '0:0'

  return (
    <S.LiveContentWrapper>
      <S.EventScore>{eventScore}</S.EventScore>
      {hasTime && (
        <S.EventElapsedTime>
          <IconLiveSmall colorToken="icon-negative" size={12} />
          {value}
        </S.EventElapsedTime>
      )}
    </S.LiveContentWrapper>
  )
}
