import React from 'react'

import { LayoutProfile } from 'layouts/profile'
import { LayoutPrimary } from 'layouts/primary'

import { Profile } from './Profile'

export const PageProfile = () => (
  <LayoutPrimary>
    <LayoutProfile pageTitle={{ text: 'profile' }}>
      <Profile />
    </LayoutProfile>
  </LayoutPrimary>
)

export default PageProfile
