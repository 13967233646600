export function formatPhoneNumber(phoneNumber: string): string {
  if (phoneNumber.length !== 12) {
    throw new Error('Неправильный формат номера телефона')
  }

  const countryCode = phoneNumber.slice(0, 2)
  const operatorCode = phoneNumber.slice(2, 5)
  const firstPart = phoneNumber.slice(5, 8)
  const secondPart = phoneNumber.slice(8, 10)
  const thirdPart = phoneNumber.slice(10, 12)

  return `${countryCode}(${operatorCode})${firstPart}-${secondPart}-${thirdPart}`
}
