import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect } from 'react'
import { Sport } from 'betweb-openapi-axios'
import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'

import { mainPageContainerActions } from 'pages/page-main/MainPageContainer/slice'
import {
  getFilterIsBroadcast,
  selectMainPageLiveEvents,
  selectLiveEventsCurrentSportId,
  selectLiveEventsFilters,
  selectLiveEventsLoaded,
  selectLiveSportsCounters,
  selectIsExistBroadcastForCurrentEvents
} from 'pages/page-main/MainPageContainer/selectors'
import { SportTabs } from 'features/sport-tabs'
import {
  StyledFiltersButtonWrapper,
  StyledMainPageEventsHeader,
  StyledMainPageEventsHeaderWrapper,
  StyledMainPageEventsWrapper,
  StyledSportListWrapper
} from 'pages/page-main/Main/components/live-events/live-events.styled'
import { EventsTable, EventsTableLoader } from 'widgets/events-table'
import { Button } from 'shared/ui/Button'
import { IconTop } from 'shared/ui/Icon/General/IconTop'
import { Skeleton } from 'shared/ui/skeleton'
import { SportTabsLoader } from 'features/sport-tabs/sport-tabs.loader'
import { IconVideoFilled } from 'shared/ui/Icon/General/IconVideoFilled'
import { useIsEventListHasTop } from 'entities/event'

export const MainPageLiveEvents = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const isFeatureFlagBroadcastEnabled = useFeatureFlag(
    EFeatureFlags.BROADCAST_ENABLED
  )

  const filters = useSelector(selectLiveEventsFilters)
  const events = useSelector(selectMainPageLiveEvents)
  const isBroadcast = useSelector(getFilterIsBroadcast)
  const sportsLiveCountersList = useSelector(selectLiveSportsCounters)
  const isLoaded = useSelector(selectLiveEventsLoaded)
  const activeSport = useSelector(selectLiveEventsCurrentSportId)
  const isBroadcastFilterButtonEnabled = useSelector(
    selectIsExistBroadcastForCurrentEvents
  )

  const { isEventListHasTop } = useIsEventListHasTop(events)

  const onSportChange = useCallback(
    (sportId: Sport['id']) => {
      dispatch(mainPageContainerActions.selectLiveEventsSport({ sportId }))
    },
    [dispatch]
  )

  const handleFiltersChange = useCallback(
    (key: keyof typeof filters, value: boolean) => () => {
      dispatch(mainPageContainerActions.setLiveEventsFilters({ key, value }))
    },
    [dispatch]
  )

  useEffect(() => {
    if (
      sportsLiveCountersList.length &&
      !sportsLiveCountersList.map((item) => item.id).includes(activeSport)
    ) {
      onSportChange(sportsLiveCountersList[0]?.id)
    }
  }, [activeSport, filters.isTop, onSportChange, sportsLiveCountersList])

  useEffect(() => {
    return () => {
      dispatch(
        mainPageContainerActions.setLiveEventsFilters({
          key: 'isTop',
          value: false
        })
      )
    }
  }, [dispatch])

  useEffect(() => {
    if (filters.isTop && !isEventListHasTop) {
      handleFiltersChange('isTop', !filters.isTop)
    }
  }, [filters.isTop, isEventListHasTop, handleFiltersChange])

  const toggleFilterBroadcast = useCallback(() => {
    dispatch(mainPageContainerActions.toggleFilterIsBroadcast())
  }, [dispatch])

  return (
    <StyledMainPageEventsWrapper>
      <StyledMainPageEventsHeaderWrapper>
        <StyledMainPageEventsHeader>
          {t('live events')}
        </StyledMainPageEventsHeader>

        <StyledFiltersButtonWrapper>
          {isFeatureFlagBroadcastEnabled && (
            <Button
              disabled={!isBroadcastFilterButtonEnabled}
              icon={IconVideoFilled}
              isActive={isBroadcast}
              view="filter"
              onClick={toggleFilterBroadcast}
            >
              {t('with broadcast')}
            </Button>
          )}
          <Button
            disabled={!isEventListHasTop}
            icon={IconTop}
            isActive={filters.isTop}
            view="filter"
            onClick={handleFiltersChange('isTop', !filters.isTop)}
          >
            {t('only top')}
          </Button>
        </StyledFiltersButtonWrapper>
      </StyledMainPageEventsHeaderWrapper>

      <StyledSportListWrapper>
        <Skeleton isLoading={!isLoaded} styledFallback={<SportTabsLoader />}>
          <SportTabs
            activeSport={activeSport}
            items={sportsLiveCountersList}
            onChange={onSportChange}
          />
        </Skeleton>
      </StyledSportListWrapper>
      <Skeleton
        count={3}
        isLoading={!isLoaded}
        styledFallback={<EventsTableLoader />}
      >
        <EventsTable
          events={events}
          headerProps={{ withIcon: true }}
          sortByTags
        />
      </Skeleton>
    </StyledMainPageEventsWrapper>
  )
}
