import React, { FC, useMemo } from 'react'
import { useGetCurrencyIcon } from 'astra-core/hooks/useGetCurrenciesIcon'
import { useSelector } from 'react-redux'
import { selectAccountBalance } from 'astra-core/containers/AccountProvider'
import { selectSettingsDataProp } from 'astra-core/containers/SettingsProvider'

import { RootState } from 'shared/types/store'
import { formatBalance } from 'shared/lib/format/format-balance'

import { StyledBalanceValue } from './balance-value.styled'

export const BalanceValue: FC = () => {
  const balance = useSelector(selectAccountBalance)
  const isBalanceHidden = useSelector((state: RootState) =>
    selectSettingsDataProp(state, 'balanceHidden')
  )

  const currencyIcon = useGetCurrencyIcon()

  const balanceView = useMemo(
    () =>
      isBalanceHidden
        ? `*** ${currencyIcon}`
        : formatBalance(balance, currencyIcon),
    [balance, currencyIcon, isBalanceHidden]
  )

  return <StyledBalanceValue>{balanceView}</StyledBalanceValue>
}
