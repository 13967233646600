import React from 'react'

import { LayoutPrimary } from 'layouts/primary'
import { LayoutEventsTable } from 'layouts/events-table'

import { FavouriteEventsContainer } from './FavouriteEventsContainer'

export const PageFavouriteEvents = () => (
  <LayoutPrimary>
    <LayoutEventsTable>
      <FavouriteEventsContainer />
    </LayoutEventsTable>
  </LayoutPrimary>
)

export default PageFavouriteEvents
