import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  betsHistoryProviderActions,
  selectBetsHistoryBetsStatus,
  selectBetsHistoryCollapsedBets,
  selectBetsHistoryPageValues
} from 'astra-core/containers/BetsHistoryProvider'
import React, { useCallback } from 'react'
import { BetStatus as BetStatusEnum } from 'betweb-openapi-axios'

import { SegmentedControl } from 'shared/ui/segmented-control'

const betTypes = [
  {
    title: 'all',
    value: BetStatusEnum.Computed
  },
  {
    title: 'history won',
    value: BetStatusEnum.Won
  },
  {
    title: 'history returned',
    value: BetStatusEnum.Returned
  },
  {
    title: 'history sold',
    value: BetStatusEnum.Sold
  },
  {
    title: 'history lost',
    value: BetStatusEnum.Lost
  }
]

export const BetStatus = () => {
  const { t } = useTranslation()
  const betsStatus = useSelector(selectBetsHistoryBetsStatus)
  const betsListTableData = useSelector(selectBetsHistoryPageValues)
  const dispatch = useDispatch()

  const { collapsedList } = useSelector(selectBetsHistoryCollapsedBets)

  const handleClick = useCallback(
    (value) => {
      if (betsStatus !== value) {
        dispatch(betsHistoryProviderActions.setFilterBetStatus(value))

        collapsedList.forEach((id) => {
          dispatch(betsHistoryProviderActions.setCollapsedBets(id))
        })
      }
    },
    [dispatch, betsStatus, collapsedList]
  )

  if (betsStatus === BetStatusEnum.Current) {
    return null
  }

  const disableOther =
    betsStatus === BetStatusEnum.Computed && !betsListTableData.length

  return (
    <SegmentedControl
      items={betTypes.map((item) => ({
        value: item.value,
        label: t(item.title),
        disabled: disableOther && item.value !== BetStatusEnum.Computed
      }))}
      itemSize={28}
      value={betsStatus as string}
      view="secondary"
      onChange={handleClick}
    />
  )
}
