import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectBannersHost } from 'pages/page-main/MainPageContainer/selectors'
import { useSourceLoaded } from 'shared/lib/source-loaded/use-source-loaded'

import { StyledBanner, StyledBannersLink } from './Banners.styled'
import { BannerProps } from './Banners.types'

export const Banner: FC<BannerProps> = ({ banner }) => {
  const host = useSelector(selectBannersHost)
  const bannerUrl = useMemo(
    () => `${host}/${banner.imageId}`,
    [banner.imageId, host]
  )

  const sourceLoaded = useSourceLoaded(bannerUrl)

  return banner.link.includes('https') ? (
    <a href={banner.link} rel="noreferrer" target="_blank">
      <StyledBanner imageUrl={sourceLoaded} />
    </a>
  ) : (
    <StyledBannersLink
      to={{
        pathname: banner.link
      }}
      rel="noreferrer"
    >
      <StyledBanner imageUrl={sourceLoaded} />
    </StyledBannersLink>
  )
}
