import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { IconUnderConstruction } from 'shared/ui/Icon/General/IconUnderConstruction'

import {
  StyledUnderConstructionContentWrapper,
  StyledUnderConstructionInfoWrapper,
  StyledUnderConstructionPrimaryText,
  StyledUnderConstructionSecondaryText,
  StyledUnderConstructionTextWrapper
} from './under-construction-content.styled'
import { IUnderConstrContentProps } from './under-construction-content.types'

export const UnderConstructionContent: FC<IUnderConstrContentProps> = ({
  children
}) => {
  const [t] = useTranslation()
  const hasBtn = Boolean(children)

  return (
    <StyledUnderConstructionContentWrapper hasBtn={hasBtn}>
      <StyledUnderConstructionInfoWrapper>
        <IconUnderConstruction />
        <StyledUnderConstructionTextWrapper>
          <StyledUnderConstructionPrimaryText>
            {t('this section is under construction')}
          </StyledUnderConstructionPrimaryText>
          <StyledUnderConstructionSecondaryText>
            {t('everything will work very soon')}
          </StyledUnderConstructionSecondaryText>
        </StyledUnderConstructionTextWrapper>
      </StyledUnderConstructionInfoWrapper>
      {children}
    </StyledUnderConstructionContentWrapper>
  )
}
