import styled from 'styled-components'

import { combineMedias } from 'shared/lib/styled'
import { BasketButtonSmallWindow } from 'features/basket/open-basket-small-window'

export const StyledMainWrapper = styled.div`
  align-content: baseline;
  display: grid;
  max-width: 100%;
  overflow-x: hidden;
  padding-bottom: 32px;
  position: relative;
`

export const StyledEventsSectionWrapper = styled.section`
  display: grid;
`

export const StyledBasketFloatingButton = styled(BasketButtonSmallWindow)`
  position: fixed;
  right: 16px;
  top: 54px;
  visibility: hidden;
  z-index: 15;
  ${combineMedias(['tabletOrMobile', 'small'])} {
    visibility: visible;
  }
`
