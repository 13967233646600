import React from 'react'

import { LayoutPrimary } from 'layouts/primary'
import { SportsMenu } from 'widgets/sports-menu'
import { NotificationsPanel } from 'widgets/notifications-panel'
import {
  ColumnLayout,
  ColumnLayoutContent,
  ColumnLayoutScroll
} from 'shared/lib/layout'
import { FooterOfPage } from 'widgets/footer-of-page'
import { RightBasketContainer } from 'widgets/basket-aside'

import { MainPageContainer } from './MainPageContainer'

export const PageMain = () => (
  <LayoutPrimary>
    <ColumnLayout>
      <SportsMenu />

      <NotificationsPanel />

      <ColumnLayoutScroll>
        <ColumnLayoutContent>
          <MainPageContainer />

          <FooterOfPage />
        </ColumnLayoutContent>
      </ColumnLayoutScroll>

      <RightBasketContainer />
    </ColumnLayout>
  </LayoutPrimary>
)

export default PageMain
