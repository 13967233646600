import React from 'react'

import { LayoutExternalFeatureProvider } from 'layouts/external-feature-provider'
import { LayoutPrimary } from 'layouts/primary'

import { LiveResults } from './LiveResults'

export const PageLiveResults = () => (
  <LayoutPrimary>
    <LayoutExternalFeatureProvider>
      <LiveResults />
    </LayoutExternalFeatureProvider>
  </LayoutPrimary>
)

export default PageLiveResults
