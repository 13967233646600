import React, { FC } from 'react'

import { LayoutError } from 'layouts/error'
import { LayoutPrimary } from 'layouts/primary'

import { Error404Page } from './Error404Page'

export const Page404: FC = () => (
  <LayoutPrimary>
    <LayoutError>
      <Error404Page />
    </LayoutError>
  </LayoutPrimary>
)

export default Page404
