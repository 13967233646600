import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectConfig } from 'astra-core/containers/ConfigProvider'

import { mainPageContainerActions } from 'pages/page-main/MainPageContainer/slice'
import { TopEvents } from 'pages/page-main/Main/components/top-events'
import { MainPageLiveEvents } from 'pages/page-main/Main/components/live-events'
import { rightBasketContainerActions } from 'entities/basket/model/slice'

import { Banners, BetGames } from './components'
import {
  StyledBasketFloatingButton,
  StyledEventsSectionWrapper,
  StyledMainWrapper
} from './Main.styled'

interface Props {}

export const Main: FC<Props> = () => {
  const dispatch = useDispatch()
  const { IS_IFRAME } = useSelector(selectConfig)

  useEffect(() => {
    dispatch(mainPageContainerActions.fetchBanners())

    return () => {
      dispatch(
        rightBasketContainerActions.toggleBasketOnSmallWindow({ isShow: false })
      )
      dispatch(mainPageContainerActions.resetFilterIsBroadcast())
    }
  }, [dispatch])

  return (
    <StyledMainWrapper>
      <StyledBasketFloatingButton />
      <Banners />
      {!IS_IFRAME ? <BetGames /> : null}
      <StyledEventsSectionWrapper>
        <TopEvents />
      </StyledEventsSectionWrapper>
      <StyledEventsSectionWrapper>
        <MainPageLiveEvents />
      </StyledEventsSectionWrapper>
    </StyledMainWrapper>
  )
}
