import { useDispatch, useSelector } from 'react-redux'
import {
  betsHistoryProviderActions,
  selectBetsHistoryCollapsedBets
} from 'astra-core/containers/BetsHistoryProvider'
import { FC, useCallback, useRef } from 'react'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'
import { useTranslation } from 'react-i18next'
import { bonusOnExpressToPercent, formatAmountLocaleString } from 'astra-core'
import { useGetCurrencyIcon } from 'astra-core/hooks'
import {
  selectLoyaltyById,
  selectLoyaltyCurrencyRestrictionById
} from 'astra-core/containers/AccountProvider'
import { BetStatus, BetType } from 'betweb-openapi-axios'

import { RootState } from 'shared/types/store'
import {
  BetsHistoryHeader,
  BetsHistoryOutcomesTable
} from 'features/bets-history-outcomes-table'
import { CellEventCashout } from 'widgets/bets-history/components/Cashout'
import { ExpressBonusModal } from 'features/express-bonus-modal/express-bonus-modal'
import { IconPolygonBgLeftPart } from 'shared/ui/Icon/General/IconPolygonBgLeftPart'
import {
  getBetBonusAmountFormatted,
  getExpressBonusSum
} from 'entities/bet-history'
import { IconInfoCircleSFilled } from 'shared/ui/Icon/General/IconInfoCircleSFilled'
import { formatMonetaryAmount } from 'shared/lib/format/formatMonetaryAmount'
import { IconBonus } from 'shared/ui/Icon/General/IconBonus'
import { IconTicketNo } from 'shared/ui/Icon/General/IconTicketNo'

import { BetHistoryCardIcon } from './bet-history-card-icon'
import { getExpressSystemOutcomesCount } from './bet-history-utils'
import { BetHistoryTag } from './bet-history-tag'
import { BetHistoryCardProps, ECellEventTitle } from './bet-history-card.types'
import {
  CardDataBlockAmount,
  CardDataBlockBonus,
  CardDataBlockCashout,
  CardDataBlockName,
  CardDataBlockOdd,
  CardDataBlockTag,
  CardDataCoupon,
  CardDataFixedContainer,
  CardDataLabel,
  CardDataValue,
  CardDate,
  CardMain,
  CardName,
  CardTable,
  CardWrapper,
  CollapseCardIcon,
  CollapsedWrapper,
  ExpressBonusContent,
  ExpressBonusLabel,
  ExpressBonusPercent,
  ExpressBonusPercentContent,
  ExpressBonusSum,
  MainInfoBlock,
  StyledBasketHistoryTextBonus,
  StyledBonusIconWrapper,
  WonAmount
} from './bet-history-card.styled'

export const BetHistoryCard: FC<BetHistoryCardProps> = ({ item }) => {
  const { t } = useTranslation()
  const cardInfoRef = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()
  const currencyIcon = useGetCurrencyIcon()
  const couponLoyalty = useSelector((state: RootState) =>
    selectLoyaltyById(state, item.appliedCouponId)
  )
  const loyaltyCurrencyRestriction = useSelector((state: RootState) =>
    selectLoyaltyCurrencyRestrictionById(state, item.appliedCouponId)
  )
  const { collapsedList } = useSelector(selectBetsHistoryCollapsedBets)

  const onClickRowCollapse = useCallback(
    () => dispatch(betsHistoryProviderActions.setCollapsedBets(item.id)),
    [dispatch, item.id]
  )

  const isCollapsed = collapsedList.includes(item.id)

  const bonusAsPercent = bonusOnExpressToPercent(item.coefMultiplier ?? 1)

  const betAmountFormatted = getBetBonusAmountFormatted({
    bet: item,
    couponLoyalty,
    currencyIcon,
    loyaltyCurrencyRestriction
  })

  const betCount =
    item.outcomes.length > 1
      ? `${getExpressSystemOutcomesCount(item)} ${t('events', {
          count: item.outcomes.length
        }).toLowerCase()}`
      : ''
  const betName = ECellEventTitle[item.betType]
    ? `${t(ECellEventTitle[item.betType])} ${betCount}`
    : ECellEventTitle.default
  const betTitle = `${betName}`

  const hideBetAmount =
    !betAmountFormatted ||
    !Number.parseInt(betAmountFormatted, 10) ||
    item.status === BetStatus.Current ||
    item.status === BetStatus.Lost ||
    item.status === BetStatus.Returned

  const cardInfoEl = cardInfoRef.current
  const collapsedHeight = cardInfoEl
    ? `${cardInfoEl.offsetHeight + 8}px`
    : undefined

  return (
    <CollapsedWrapper
      collapsedHeight={collapsedHeight}
      isCollapsed={isCollapsed}
    >
      <CardWrapper>
        <CardMain ref={cardInfoRef} onClick={onClickRowCollapse}>
          <MainInfoBlock>
            <BetHistoryCardIcon betStatus={item.status} />
            <CardDataBlockName>
              <CardName>{betTitle}</CardName>
              <CardDate>
                {dayjs(item.timestamp).format('DD.MM.YY HH:mm')}
              </CardDate>
            </CardDataBlockName>
          </MainInfoBlock>

          <CardDataBlockCashout>
            <CellEventCashout id={item.id} />
          </CardDataBlockCashout>

          <CardDataBlockAmount justifyContent="flex-end">
            <CardDataLabel>{t('bet amount')}</CardDataLabel>
            {couponLoyalty?.coupon.amount ? (
              <CardDataCoupon>
                <IconTicketNo />
                {t('coupon applied')}
                {formatAmountLocaleString({
                  value: couponLoyalty?.coupon.amount,
                  currency: currencyIcon
                })}
              </CardDataCoupon>
            ) : (
              <CardDataValue>
                {item.bonusWallet ? (
                  <StyledBasketHistoryTextBonus>
                    {formatAmountLocaleString({
                      value: item.bet
                    })}
                    <IconBonus size={12} />
                  </StyledBasketHistoryTextBonus>
                ) : (
                  formatAmountLocaleString({
                    value: item.bet,
                    currency: currencyIcon
                  })
                )}
              </CardDataValue>
            )}
          </CardDataBlockAmount>

          <CardDataBlockOdd justifyContent="flex-end">
            {item.betType !== BetType.Ordinar ? (
              <>
                <CardDataLabel>{t('total odds')}</CardDataLabel>
                <CardDataValue>
                  {formatAmountLocaleString({
                    value: item.coefficient?.toFixed(2)
                  })}
                </CardDataValue>
              </>
            ) : null}
          </CardDataBlockOdd>

          <CardDataFixedContainer>
            {item.betType !== BetType.System ||
            item.status !== BetStatus.Current ? (
              <>
                {item.betType === BetType.Express &&
                item.status !== BetStatus.Sold &&
                getExpressBonusSum(item) > 0 ? (
                  <CardDataBlockBonus>
                    <CardDataLabel>
                      <ExpressBonusLabel>
                        <ExpressBonusModal>
                          <StyledBonusIconWrapper>
                            <IconInfoCircleSFilled />
                          </StyledBonusIconWrapper>
                        </ExpressBonusModal>
                        {t('bonus')}
                      </ExpressBonusLabel>
                    </CardDataLabel>

                    <ExpressBonusContent>
                      <ExpressBonusSum>
                        +{formatMonetaryAmount(getExpressBonusSum(item))}{' '}
                        <div>{currencyIcon}</div>
                      </ExpressBonusSum>
                      <ExpressBonusPercent>
                        <IconPolygonBgLeftPart />
                        <ExpressBonusPercentContent>
                          {bonusAsPercent}%
                        </ExpressBonusPercentContent>
                        <IconPolygonBgLeftPart twist={180} />
                      </ExpressBonusPercent>
                    </ExpressBonusContent>
                  </CardDataBlockBonus>
                ) : null}
              </>
            ) : null}

            <CardDataBlockTag justifyContent="flex-end">
              <BetHistoryTag status={item.status} />
              {!hideBetAmount && <WonAmount>{betAmountFormatted}</WonAmount>}
            </CardDataBlockTag>

            <CollapseCardIcon size={16} twist={!isCollapsed ? 180 : 0} />
          </CardDataFixedContainer>
        </CardMain>

        <CardTable>
          <BetsHistoryHeader />

          <BetsHistoryOutcomesTable outcomes={item.outcomes} />
        </CardTable>
      </CardWrapper>
    </CollapsedWrapper>
  )
}
