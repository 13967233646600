import React, { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  betsHistoryProviderActions,
  selectBetsHistoryBetsStatus,
  selectBetsHistoryCollapsedBets
} from 'astra-core/containers/BetsHistoryProvider'

import { NavTabs } from 'shared/ui/nav-tabs'

import { SECTION_SELECT_OPTIONS } from './constants'
import { NavTabsWrapper } from './SectionSelect.styled'

export const SectionSelect = memo(() => {
  const betsStatus = useSelector(selectBetsHistoryBetsStatus)
  const dispatch = useDispatch()
  const { collapsedList } = useSelector(selectBetsHistoryCollapsedBets)

  const handleClick = useCallback(
    (value) => {
      if (betsStatus !== value) {
        dispatch(betsHistoryProviderActions.setFilterBetStatus(value))

        collapsedList.forEach((id) => {
          dispatch(betsHistoryProviderActions.setCollapsedBets(id))
        })
      }
    },
    [dispatch, betsStatus, collapsedList]
  )

  return (
    <NavTabsWrapper>
      <NavTabs<true>
        items={SECTION_SELECT_OPTIONS}
        value={betsStatus as string}
        isControlled
        onChange={handleClick}
      />
    </NavTabsWrapper>
  )
})
