import { IconBalance } from 'shared/ui/Icon/General/IconBalance'
import { IconProfile } from 'shared/ui/Icon/General/IconProfile'
import { ERoutes } from 'shared/types/routes'
import { IconDocFilled } from 'shared/ui/Icon/General/IconDocFilled'
import { IconBetsHistory } from 'shared/ui/Icon/General/IconBetsHistory'
import { IconPromotions } from 'shared/ui/Icon/General/IconPromotions'
import { IconStatefulNotification } from 'features/icon-stateful-notification'
import { NotificationCounter } from 'widgets/notifications/components'
import { IconInfoCircleFilled } from 'shared/ui/Icon/General/IconInfoCircleFilled'
import { IconLock } from 'shared/ui/Icon/General/IconLock'
import { IconMyBonuses } from 'shared/ui/Icon/General/IconMyBonuses'
import { MyBonusesCounter } from 'widgets/my-bonuses/components/my-bonuses-counter'
import { IconBonusClub } from 'shared/ui/Icon/General/icon-bonus-club'

import { INavMenuItemRoute } from './profile-nav-menu.type'

export const PROFILE_MENU_AUTHORIZED_ROUTES_LIST: INavMenuItemRoute[] = [
  { path: ERoutes.Profile, locale: 'profile', icon: IconProfile },
  {
    path: ERoutes.Notifications,
    locale: 'notifications',
    icon: IconStatefulNotification,
    counter: NotificationCounter
  },
  {
    path: ERoutes.Promotions,
    locale: 'promotions',
    icon: IconPromotions
  },
  {
    path: ERoutes.BonusClub,
    locale: 'bonus_club.title',
    icon: IconBonusClub
  },
  {
    path: ERoutes.MyBonuses,
    locale: 'my bonuses',
    icon: IconMyBonuses,
    counter: MyBonusesCounter
  },
  { path: ERoutes.ProfileBalance, locale: 'balance', icon: IconBalance },
  { path: ERoutes.BetsHistory, locale: 'history bets', icon: IconBetsHistory },
  {
    path: ERoutes.Documents,
    locale: 'documents',
    icon: IconDocFilled
  },
  {
    path: ERoutes.About,
    locale: 'about company',
    icon: IconInfoCircleFilled
  },
  {
    path: ERoutes.ResponsibleGambling,
    locale: 'responsible gambling',
    icon: IconLock
  }
]

export const PROFILE_MENU_NON_AUTHORIZED_ROUTES_LIST: INavMenuItemRoute[] = [
  {
    path: ERoutes.Promotions,
    locale: 'promotions',
    icon: IconPromotions
  },
  {
    path: ERoutes.Documents,
    locale: 'documents',
    icon: IconDocFilled
  },
  {
    path: ERoutes.About,
    locale: 'about company',
    icon: IconInfoCircleFilled
  },
  {
    path: ERoutes.ResponsibleGambling,
    locale: 'responsible gambling',
    icon: IconLock
  }
]
