import styled, { css } from 'styled-components'

import { IconError404 } from 'shared/ui/Icon/General/IconError404'
import { typography } from 'shared/lib/theme/typography'

export const StyledErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  width: 100vw;
`

export const StyledIconError404 = styled(IconError404)`
  & > svg:nth-child(1) {
    ${({ theme }) => css`
      fill: ${theme.tokens.colors.icon['icon-warning']};
    `};
  }
`

export const StyledErrorTitle = styled.h1`
  ${typography['Body / 16 Bold']}

  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-primary']};
    padding-top: ${theme.tokens.alias.space['space-xs-8']}px;
  `}
`

export const StyledErrorDescr = styled.p`
  ${typography['Body / 14 SemiBold']}
  ${({ theme }) => css`
    color: ${theme.tokens.colors.text['text-secondary-3']};
    text-align: center;
  `}
`
