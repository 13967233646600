import {
  resultsProviderActions,
  selectFilterIsLiveResults,
  selectResultsFilterDate
} from 'astra-core/containers/ResultsProvider'
import dayjs from 'dayjs'
import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import updateLocale from 'dayjs/plugin/updateLocale'
import 'dayjs/locale/ru'

import { isCurrentYear, isDateToday } from 'shared/lib/todayDate'
import { CustomButtonRange, DatePicker } from 'shared/ui/DatePicker'
import { IconChevronDown } from 'shared/ui/Icon/General/IconChevronDown'

import { StyledCalendarTitleDate } from './result-calendar.styled'

dayjs.extend(updateLocale)

dayjs.updateLocale('ru', {
  monthsShort: [
    'янв',
    'фев',
    'мар',
    'апр',
    'май',
    'июн',
    'июл',
    'авг',
    'сент',
    'окт',
    'ноя',
    'дек'
  ]
})

export const ResultCalendar = () => {
  const dispatch = useDispatch()
  const isLiveResults = useSelector(selectFilterIsLiveResults)
  const [active, setActive] = useState(false)

  const date = useSelector(selectResultsFilterDate)

  const onResetDatePicker = useCallback(() => {
    dispatch(resultsProviderActions.resetDatePickerRangeValues())
    dispatch(resultsProviderActions.fetchResults({ withLinkedEvents: false }))
  }, [dispatch])

  const handleChangeDateFilter = useCallback(
    (selectedDate) => {
      dispatch(resultsProviderActions.setDateFilter({ date: selectedDate }))
    },
    [dispatch]
  )

  const handleClose = useCallback(() => {
    setActive(false)
  }, [])

  const handleOpen = useCallback(() => {
    setActive(true)
  }, [])

  const isNotToday = useMemo(() => !isDateToday(date), [date])

  const dateFormat = useMemo(
    () => (isCurrentYear(date) ? 'DD MMMM' : 'DD MMM YYYY'),
    [date]
  )

  return (
    <DatePicker
      customInput={
        <CustomButtonRange
          active={active}
          customWidth="max-content"
          isDisabled={isLiveResults}
          isVisible={isNotToday}
          singleDateFormat={dateFormat}
          startDate={date}
          isSingleDate
          onReset={onResetDatePicker}
        />
      }
      renderCustomHeader={({ monthDate, decreaseMonth, increaseMonth }) => (
        <div>
          <button
            aria-label="Previous Month"
            className="react-datepicker__navigation react-datepicker__navigation--previous"
            onClick={decreaseMonth}
          >
            <IconChevronDown twist={90} />
          </button>
          <StyledCalendarTitleDate>
            {dayjs(monthDate).locale('ru').format('MMMM YYYY')}
          </StyledCalendarTitleDate>
          <button
            aria-label="Next Month"
            className="react-datepicker__navigation react-datepicker__navigation--next"
            onClick={increaseMonth}
          >
            <IconChevronDown twist={-90} />
          </button>
        </div>
      )}
      calendarStartDay={1}
      disabled={isLiveResults}
      isTodayActive={!isNotToday}
      maxDate={new Date()}
      monthsShown={1}
      selected={date}
      onCalendarClose={handleClose}
      onCalendarOpen={handleOpen}
      onChange={handleChangeDateFilter}
    />
  )
}
