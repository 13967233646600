import { LayoutTheme } from 'betweb-openapi-axios'
import React from 'react'
import { useTheme } from 'styled-components'

export const IconUnderConstruction = () => {
  const theme = useTheme()
  const isLight = theme.typeLayoutTheme === LayoutTheme.Light

  return (
    <svg
      fill="none"
      height="40"
      viewBox="0 0 40 40"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M25.5475 0C27.3041 0 28.9717 0.688889 30.239 1.95556L38.0211 9.75556C39.2885 11 40 12.6889 40 14.4667V25.5111C40 27.2889 39.2885 28.9556 38.0211 30.2222L30.2168 38.0222C28.9717 39.2889 27.2818 40 25.5031 40H14.4747C12.6959 40 11.0283 39.2889 9.76098 38.0222L1.95664 30.2222C0.689272 28.9778 0 27.2889 0 25.5111V14.4889C0 12.7111 0.689272 11.0444 1.95664 9.77778L9.80545 1.95556C11.0283 0.688889 12.7404 0 14.4969 0H25.5475Z"
        fill={isLight ? '#fff' : '#2A303C'}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M10.4278 10C9.90453 10 9.46974 10.4034 9.4306 10.9252L8.06448 29.1402C8.02967 29.6043 8.39684 30 8.86224 30H10.5722C11.0955 30 11.5302 29.5966 11.5694 29.0748L12.9355 10.8598C12.9703 10.3957 12.6031 10 12.1377 10H10.4278ZM27.8622 10C27.3968 10 27.0297 10.3957 27.0645 10.8598L28.4306 29.0748C28.4697 29.5966 28.9045 30 29.4278 30H31.1377C31.6031 30 31.9703 29.6043 31.9355 29.1402L30.5555 10.7402C30.5242 10.3227 30.1764 10 29.7577 10H27.8622Z"
        fill={isLight ? '#C4C9D4' : '#414958'}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M6 13.6494V15.924L11.072 20.996H14.9089L6.57406 12.6611L6.29289 12.9423C6.10536 13.1298 6 13.3842 6 13.6494ZM34 19.3466V17.059L28.941 12H25.104L33.4324 20.3284L33.7071 20.0537C33.8946 19.8661 34 19.6118 34 19.3466ZM21.2651 12H17.4282L26.4243 20.9961H30.2612L21.2651 12ZM13.589 12H9.75208L18.7482 20.9961H22.5851L13.589 12Z"
        fill={isLight ? '#A7AFBE' : '#576175'}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M6 15.925V19.3465C6 19.6117 6.10536 19.8661 6.29289 20.0536L6.9424 20.7031C7.12994 20.8907 7.38429 20.996 7.64951 20.996H11.071L6 15.925ZM34 17.0579V13.6495C34 13.3843 33.8946 13.1299 33.7071 12.9424L33.0576 12.2929C32.8701 12.1054 32.6157 12 32.3505 12H28.9421L34 17.0579ZM25.1029 12H21.2659L30.262 20.9961H32.3505C32.6157 20.9961 32.8701 20.8907 33.0576 20.7032L33.4318 20.3289L25.1029 12ZM17.4271 12H13.5901L22.5862 20.9961H26.4232L17.4271 12ZM9.75097 12H7.64951C7.38429 12 7.12994 12.1054 6.9424 12.2929L6.57465 12.6606L14.9101 20.9961H18.7471L9.75097 12Z"
        fill={isLight ? '#576175' : '#A7AFBE'}
        fillRule="evenodd"
      />
    </svg>
  )
}
