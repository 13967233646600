import React, { FC, useCallback, useEffect, useState } from 'react'
import { getCurrentLng } from 'astra-core/utils'
import { selectConfig } from 'astra-core/containers/ConfigProvider'
import { useSelector } from 'react-redux'

import { LoaderSpinner } from 'shared/ui/LoaderSpinner'

import { IFRAME_DATA } from './LiveResults.constants'
import {
  StyledLiveResultsWrapper,
  StyledLoadingIndicatorLiveResultsWrapper
} from './LiveResults.styled'

export const LiveResults: FC = () => {
  const [isLoading, setIsLoading] = useState(true)

  const currentLang = getCurrentLng()
  const { BETRADAR_LIVE_RESULTS_URL } = useSelector(selectConfig)

  useEffect(() => {
    setIsLoading(true)
  }, [currentLang])

  const handleStatisticsIframeLoaded = useCallback(() => {
    setIsLoading(false)
  }, [])

  return (
    <StyledLiveResultsWrapper>
      {isLoading && (
        <StyledLoadingIndicatorLiveResultsWrapper>
          <LoaderSpinner />
        </StyledLoadingIndicatorLiveResultsWrapper>
      )}
      <iframe
        frameBorder="0"
        id={IFRAME_DATA.id}
        src={`${BETRADAR_LIVE_RESULTS_URL}/${currentLang}`}
        style={IFRAME_DATA.styles}
        title="iframeLiveResultsId"
        onLoad={handleStatisticsIframeLoaded}
      />
    </StyledLiveResultsWrapper>
  )
}
