import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  betsHistoryProviderActions,
  selectBetsStatisticsList
} from 'astra-core/containers/BetsHistoryProvider'
import { adjustAmountForFrontend } from 'astra-core'
import {
  EFeatureFlags,
  useFeatureFlag
} from 'astra-core/containers/ConfigProvider'

import { History } from 'widgets/bets-history/components'
import { StyledWrapper } from 'widgets/bets-history/BetsHistory.styled'
import { BalanceReport } from 'features/balance-report'

import { BETS_STATISTICS_LIST_ITEMS } from './bets-history.constants'

export const BetsHistory: FC = () => {
  const dispatch = useDispatch()
  const isCashoutEnabled = useFeatureFlag(EFeatureFlags.CASHOUT_ENABLED)

  useEffect(() => {
    dispatch(betsHistoryProviderActions.fetchBetsStatistics())
  }, [dispatch])

  useEffect(() => {
    if (isCashoutEnabled) {
      dispatch(betsHistoryProviderActions.subscribeCashoutSSE())

      return () => {
        dispatch(betsHistoryProviderActions.unsubscribeCashoutSSE())
      }
    }
  }, [dispatch, isCashoutEnabled])

  useEffect(() => {
    return () => {
      dispatch(betsHistoryProviderActions.resetBetsHistory())
    }
  }, [dispatch])

  const { betsStatisticsEntries } = useSelector(selectBetsStatisticsList)

  return (
    <StyledWrapper>
      <BalanceReport
        data={BETS_STATISTICS_LIST_ITEMS.map((item) => ({
          title: item.title,
          value: adjustAmountForFrontend(
            betsStatisticsEntries[item.key]?.amount
          ),
          withDivider: item.withDivider
        }))}
      />
      <History />
    </StyledWrapper>
  )
}
