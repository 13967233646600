import React, { FC, memo } from 'react'
import { BetStatus } from 'betweb-openapi-axios'

import { IconClock } from 'shared/ui/Icon/General/IconClock'
import { IconCircleArrowToCenter } from 'shared/ui/Icon/General/IconCircleArrowToCenter'
import { IconCircleMinus } from 'shared/ui/Icon/General/IconCircleMinus'
import { IconProps } from 'shared/ui/Icon'
import { IconCheckCircleFilled24 } from 'shared/ui/Icon/General/IconCheckCircleFilled24'

type TDataIcon = Partial<
  Record<
    BetStatus,
    {
      icon: FC
      settings: IconProps
    }
  >
>
const data: TDataIcon = {
  [BetStatus.Current]: {
    icon: IconClock,
    settings: {
      colorToken: 'icon-secondary-3'
    }
  },
  [BetStatus.Won]: {
    icon: IconCheckCircleFilled24,
    settings: {
      colorToken: 'icon-positive'
    }
  },
  [BetStatus.Returned]: {
    icon: IconCircleArrowToCenter,
    settings: {
      colorToken: 'icon-warning'
    }
  },
  [BetStatus.Lost]: {
    icon: IconCircleMinus,
    settings: {
      colorToken: 'icon-secondary'
    }
  }
}

export const BetHistoryCardIcon: FC<{ betStatus: BetStatus }> = memo(
  ({ betStatus }) => {
    const item = data[betStatus]

    if (!item) return null

    const SelectedIcon: FC<IconProps> = item.icon

    return <SelectedIcon size={24} {...item.settings} /> || null
  }
)
