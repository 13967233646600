import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  betsHistoryProviderActions,
  selectBetsHistoryDataBetsFetchItems,
  selectBetsHistoryPageValues
} from 'astra-core/containers/BetsHistoryProvider'

import { FooterOfPage } from 'widgets/footer-of-page'
import { useFetchBetsHistoryPage } from 'pages/page-bets-history/BetsHistoryPageContainer/hooks'
import {
  Filter,
  SectionSelect
} from 'widgets/bets-history/components/History/components'
import {
  StyledColumn,
  StyledHistory,
  StyledListWrapper
} from 'widgets/bets-history/components/History/History.styled'
import { HistoryList } from 'widgets/bets-history/components/History/components/list'
import { LoaderSpinner } from 'shared/ui/LoaderSpinner'

import { PAGINATION_SETTINGS } from './constants'

export const History = () => {
  const dispatch = useDispatch()
  const betsListTableData = useSelector(selectBetsHistoryPageValues)
  const betsFetchItems = useSelector(selectBetsHistoryDataBetsFetchItems)

  useFetchBetsHistoryPage()

  useEffect(() => {
    return () => {
      dispatch(betsHistoryProviderActions.resetBetsHistory())
    }
  }, [dispatch])

  return (
    <StyledHistory>
      <SectionSelect />
      <Filter />

      <StyledColumn>
        {betsListTableData.length ? (
          <StyledListWrapper pagination={PAGINATION_SETTINGS}>
            <HistoryList />
          </StyledListWrapper>
        ) : (
          <>
            <>{betsFetchItems.loading ? <LoaderSpinner /> : <HistoryList />}</>
            <FooterOfPage />
          </>
        )}
      </StyledColumn>
    </StyledHistory>
  )
}
