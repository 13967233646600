import React, { FC, useCallback } from 'react'
import { useSelector } from 'react-redux'
import {
  selectMainOutcomeTypes,
  selectSport
} from 'astra-core/containers/CommonDataProvider'
import { useProbs } from 'astra-core/hooks/useEvents'
import { EventStatus } from 'betweb-openapi-axios'

import { useEventCardHotProbs } from 'features/event-card'
import { RootState } from 'shared/types/store'
import { ProbabilityCell } from 'features/probability-cell/ui'
import { getMainOutcomeTypeProbability } from 'shared/lib/events'

import * as S from './probs.styled'
import { EventCardProbabilitiesProps } from './probs.types'

export const Probs: FC<EventCardProbabilitiesProps> = ({ event }) => {
  const sportData = useSelector((state: RootState) =>
    selectSport(state, event.tournament.sportId)
  )
  const probs = useProbs(event)
  const mainOutcomeTypes = useSelector(selectMainOutcomeTypes)

  const getOutcomeProbability = useCallback(
    (outcomeTypeName) =>
      getMainOutcomeTypeProbability({
        outcomeType: outcomeTypeName,
        probs,
        mainOutcomeTypes,
        eventId: event.id
      }),
    [event.id, mainOutcomeTypes, probs]
  )
  const typesMap = useEventCardHotProbs({
    sportId: sportData?.id
  })

  const isSuspended = event.status === EventStatus.Suspended

  return (
    <S.EventCardProbabilitiesWrapper>
      {typesMap.map((type) => (
        <ProbabilityCell
          event={event}
          eventProbability={getOutcomeProbability(type)}
          isSuspended={isSuspended}
          key={JSON.stringify(type)}
        />
      ))}
    </S.EventCardProbabilitiesWrapper>
  )
}
