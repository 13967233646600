import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { selectFavouriteEvents } from 'astra-core/containers/FavouritesProvider'
import { useMemo } from 'react'

import { TitleLinePage } from 'shared/ui/TitleLinePage'
import { ESportsTypes } from 'typings/exportable'

import { selectFavouriteEventsFilters } from '../FavouriteEventsContainer/selectors'

import { Filters } from './components'
import { StyledCalendarEvents } from './FavouriteEvents.styled'
import { EmptyScreen } from './components/empty-screen'
import { filterEvents } from './lib'
import { EventsFavoriteTable } from './components/events-favorite-table'

export const FavouriteEvents = () => {
  const [t] = useTranslation()
  const events = useSelector(selectFavouriteEvents)

  const { sportsType } = useSelector(selectFavouriteEventsFilters)

  const filteredEvents = useMemo(
    () => filterEvents(events, sportsType),
    [events, sportsType]
  )

  return (
    <StyledCalendarEvents>
      <TitleLinePage title={t('favorite')} withBorder={false}>
        {events.length > 0 && <Filters />}
      </TitleLinePage>

      {filteredEvents.length > 0 ? (
        <EventsFavoriteTable
          events={filteredEvents}
          headerProps={{ withIcon: true }}
        />
      ) : (
        <>
          {sportsType === null && <EmptyScreen view="all" />}
          {sportsType === ESportsTypes.Line && <EmptyScreen view="line" />}
          {sportsType === ESportsTypes.Live && <EmptyScreen view="live" />}
        </>
      )}
    </StyledCalendarEvents>
  )
}
