import React, { useMemo } from 'react'
import { CompletionStatus } from 'betweb-openapi-axios'
import { getEventHomeAwayCompetitors } from 'astra-core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectMatchPhase } from 'astra-core/containers/CommonDataProvider'

import { getCompetitorsNames } from 'shared/lib/events'
import { Typography } from 'shared/ui/typography'
import { IconTime } from 'shared/ui/Icon/General/icon-time'
import { EEventStatisticsCodes } from 'shared/lib/events/types'
import { useTimerCountdownForResult } from 'shared/lib/events/timer-countdown'
import {
  getEventStatusDesc,
  getResultDateAndTime
} from 'widgets/results/utils/constants'

import { TEventProps } from '../../results.types'
import { ResultSets } from '../result-sets'

import {
  StyledEventCompetitors,
  StyledEventInfo,
  StyledEventResult,
  StyledEventLiveTrack,
  StyledEvenLiveTimeWrapper,
  StyledResultEventWrapper,
  IconWrapper
} from './result-event.styled'

export const ResultEvent = ({ event }: TEventProps) => {
  const { eventDate, completionStatus, status } = event
  const [t] = useTranslation()

  const matchPhaseStat = event.liveStatistics?.find(
    (s) => s.code === EEventStatisticsCodes.MatchPhase
  )

  const matchPhase = useSelector((state) =>
    selectMatchPhase(state, matchPhaseStat ? +matchPhaseStat!.value! : 0)
  )

  const isLive = useMemo(
    () => CompletionStatus.Live === completionStatus,
    [completionStatus]
  )

  const { resultDate, resultTime } = useMemo(
    () => getResultDateAndTime(eventDate, t),
    [eventDate, t]
  )

  const eventStatusDesc = useMemo(() => getEventStatusDesc(status), [status])

  const { hasTime, value } = useTimerCountdownForResult({ event })

  const { homeCompetitors, awayCompetitors } = useMemo(
    () => getEventHomeAwayCompetitors(event),
    [event]
  )

  const homeCompetitorsNames = getCompetitorsNames(homeCompetitors)
  const awayCompetitorsNames = getCompetitorsNames(awayCompetitors)

  return (
    <StyledResultEventWrapper>
      <StyledEventInfo>
        <StyledEventLiveTrack>
          {isLive ? (
            <>
              <StyledEvenLiveTimeWrapper>
                <IconWrapper>
                  <IconTime size={12} />
                </IconWrapper>

                {hasTime || matchPhase ? (
                  <Typography color="text-primary-2" font="Hint / 10 SemiBold">
                    {value || matchPhase.name}
                  </Typography>
                ) : (
                  <Typography color="text-primary-2" font="Hint / 10 SemiBold">
                    {t('live')}
                  </Typography>
                )}
              </StyledEvenLiveTimeWrapper>

              {matchPhase && value && (
                <Typography color="text-secondary-2" font="Hint / 10 Medium">
                  {matchPhase.name}
                </Typography>
              )}
            </>
          ) : (
            <>
              <Typography color="text-primary-2" font="Hint / 10 SemiBold">
                {resultDate}
              </Typography>
              <Typography color="text-secondary-2" font="Hint / 10 Medium">
                {resultTime}
              </Typography>
            </>
          )}
        </StyledEventLiveTrack>
        <StyledEventCompetitors>
          <Typography color="text-primary" font="Body / 12 SemiBold">
            {homeCompetitorsNames}
          </Typography>
          <Typography color="text-primary" font="Body / 12 SemiBold">
            {awayCompetitorsNames}
          </Typography>
          {eventStatusDesc && (
            <Typography color="text-description" font="Hint / 10 Medium">
              {t(eventStatusDesc)}
            </Typography>
          )}
        </StyledEventCompetitors>
      </StyledEventInfo>
      <StyledEventResult>
        <ResultSets event={event} />
      </StyledEventResult>
    </StyledResultEventWrapper>
  )
}
