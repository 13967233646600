import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectConfig } from 'astra-core/containers/ConfigProvider'

import { IconArrowRight } from 'shared/ui/Icon/General/IconArrowRight'

import { DOCUMENTS } from '../constants'

import {
  StyledDocumentsLink,
  StyledDocumentsPage,
  StyledDocumentsPageContainer
} from './DocumentsPage.styled'

export const DocumentsPage = () => {
  const [t] = useTranslation()
  const { IS_IFRAME } = useSelector(selectConfig)

  return (
    <StyledDocumentsPage>
      <StyledDocumentsPageContainer>
        {DOCUMENTS.filter((doc) => (IS_IFRAME ? !doc.hideInIframe : true)).map(
          (document) => (
            <StyledDocumentsLink
              key={document.name}
              to={`/profile/documents?name=${document.name}`}
            >
              {t(document.linkName)}

              <IconArrowRight colorToken="icon-secondary-2" />
            </StyledDocumentsLink>
          )
        )}
      </StyledDocumentsPageContainer>
    </StyledDocumentsPage>
  )
}
