import React, { FC } from 'react'

import { HistoryLegacy } from './components'
import { StyledWrapper } from './bets-history-legacy.styled'

export const BetsHistoryLegacy: FC = () => {
  return (
    <StyledWrapper>
      <HistoryLegacy />
    </StyledWrapper>
  )
}
