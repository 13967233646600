import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  resultsProviderActions,
  selectResultCountersList
} from 'astra-core/containers/ResultsProvider'
import { ESportsCodes } from 'astra-core/containers/CommonDataProvider'
// import { useResultsActiveItem } from 'astra-core/hooks/useResultsActiveItem'
import { useTranslation } from 'react-i18next'

import { Skeleton } from 'shared/ui/skeleton'
import { SHORTNAMED_SPORT_CODES } from 'shared/lib/locales/sports'
import { SportIcon } from 'shared/ui/Icon/sport-icon'
import { ERoutes } from 'shared/types/routes'

import { ResultsMenuItemProps } from './results-menu-list.types'
import {
  StyledResultsMenuList,
  StyledResultsMenuItem,
  StyledResultsMenuItemText,
  StyledResultsMenuItemCount
} from './results-menu-list.styled'
import { SportMenuListLoader } from './results-menu-list.loader'

export const ResultsMenuList = () => {
  const [hasDataLoadedOnce, setHasDataLoadedOnce] = useState(false)

  const resultsCountersList = useSelector(selectResultCountersList)

  // All sport tab that will added

  // const allResultsCounts = useMemo(() => {
  //   return resultsCountersList.reduce((sum, sport) => sum + sport.count, 0)
  // }, [resultsCountersList])

  // const resultsList = useMemo(() => {
  //   const result = [...resultsCountersList]

  //   result.unshift({
  //     id: 0,
  //     name: t('all sports'),
  //     top: false,
  //     code: EAdditionalSportsCodes.ALL_SPORTS,
  //     count: allResultsCounts,
  //     isActive: true
  //   })

  //   return result
  // }, [resultsCountersList, t, allResultsCounts])

  const isLoading = !hasDataLoadedOnce && !resultsCountersList.length

  useEffect(() => {
    if (resultsCountersList.length > 0) {
      setHasDataLoadedOnce(true)
    }
  }, [resultsCountersList])

  return (
    <StyledResultsMenuList>
      <Skeleton isLoading={isLoading} styledFallback={<SportMenuListLoader />}>
        {resultsCountersList.map((sport) => (
          <ResultsMenuItem
            isActive={sport.isActive}
            key={sport.id}
            sport={sport as Required<typeof sport>}
          />
        ))}
      </Skeleton>
    </StyledResultsMenuList>
  )
}

const ResultsMenuItem: FC<ResultsMenuItemProps> = memo(
  ({ sport, isActive }) => {
    const dispatch = useDispatch()
    const { t, i18n } = useTranslation()

    const changeCurrentSportId = useCallback(() => {
      dispatch(
        resultsProviderActions.setCurrentSportIdFilter({ sportId: sport.id })
      )
    }, [dispatch, sport.id])

    const name =
      SHORTNAMED_SPORT_CODES[sport.code] &&
      i18n.exists(SHORTNAMED_SPORT_CODES[sport.code])
        ? t(SHORTNAMED_SPORT_CODES[sport.code])
        : sport.name

    return (
      <StyledResultsMenuItem
        $isActive={isActive}
        sport={sport.code as ESportsCodes}
        to={`${ERoutes.Results}`}
        onClick={changeCurrentSportId}
      >
        <SportIcon sport={sport.code as ESportsCodes} />

        <StyledResultsMenuItemText>{name}</StyledResultsMenuItemText>

        <StyledResultsMenuItemCount>{sport.count}</StyledResultsMenuItemCount>
      </StyledResultsMenuItem>
    )
  }
)
