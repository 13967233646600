import { selectLoyaltyCouponCreatedStatusLength } from 'astra-core/containers/BasketProvider'
import { useSelector } from 'react-redux'

import { INavMenuItemCounter } from 'features/profile-nav-menu/profile-nav-menu.type'

export const MyBonusesCounter: INavMenuItemCounter = ({ children }) => {
  const bonusCouponsLength = useSelector(selectLoyaltyCouponCreatedStatusLength)

  const count = bonusCouponsLength || 0

  return children ? children(count) : null
}
