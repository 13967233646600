import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  ESportsCodes,
  selectSport
} from 'astra-core/containers/CommonDataProvider'
import { selectFetchItemsResults } from 'astra-core/containers/ResultsProvider'
import orderBy from 'lodash/orderBy'
import dayjs from 'dayjs'

import { Typography } from 'shared/ui/typography'
import { SportIcon } from 'shared/ui/Icon/sport-icon'
import { RootState } from 'shared/types/store/root-state'
import {
  selectFilteredChampionships,
  selectFilteredChampionshipsLength
} from 'widgets/results/utils/selectors'

import { ResultEvent } from '../result-event'
import { ResultTitleSets } from '../result-sets'
import { TChampionshipProps } from '../../results.types'
import { ResultEmpty } from '../result-empty/result-empty'

import {
  StyledResultChampionshipTitle,
  StyledResultChampionshipWrapper,
  StyledResultTitleAndIcon,
  StyledSportIconWrapper
} from './result-championships.styled'

export const ResultChampionships = () => {
  const filteredChampionships = useSelector(selectFilteredChampionships)
  const isEmptyChampionships = useSelector(selectFilteredChampionshipsLength)
  const isLoadingResults = useSelector(selectFetchItemsResults)

  const sortedChampionships = useMemo(
    () =>
      orderBy(
        filteredChampionships,
        [(c) => c?.tournament.name.toLowerCase()],
        ['asc']
      )
        .filter((c) => c !== undefined && c !== null)
        .map((c) => c!),
    [filteredChampionships]
  )

  if (!isEmptyChampionships && !isLoadingResults) return <ResultEmpty />

  return (
    <StyledResultChampionshipWrapper>
      {sortedChampionships.map((championship) => (
        <Championship
          championship={championship}
          key={championship.tournament.id}
        />
      ))}
    </StyledResultChampionshipWrapper>
  )
}

export const Championship = ({ championship }: TChampionshipProps) => {
  const {
    tournament: { name },
    events
  } = championship

  const code = useSelector(
    (state: RootState) =>
      selectSport(state, championship.tournament.sportId).code
  )

  const sortedEvents = useMemo(
    () => orderBy(events, [(e) => dayjs(e.eventDate).unix()], ['asc']),
    [events]
  )

  return (
    <>
      <StyledResultChampionshipTitle>
        <StyledResultTitleAndIcon>
          <StyledSportIconWrapper>
            <SportIcon size="s" sport={code as ESportsCodes} />
          </StyledSportIconWrapper>

          <Typography
            color="text-secondary-2"
            font="Body / 12 Medium"
            lineClamp={1}
          >
            {name}
          </Typography>
        </StyledResultTitleAndIcon>

        <ResultTitleSets
          events={sortedEvents}
          sportName={code as ESportsCodes}
        />
      </StyledResultChampionshipTitle>

      {sortedEvents.map((event) => (
        <ResultEvent event={event} key={event.id} />
      ))}
    </>
  )
}
