import styled, { css } from 'styled-components'

import { typography } from 'shared/lib/theme/typography'

import { IHasBtn } from './under-construction-content.types'

export const StyledUnderConstructionContentWrapper = styled.div<IHasBtn>`
  ${({ theme, hasBtn }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: ${theme.tokens.alias.space['space-m-16']}px;

    ${!hasBtn &&
    css`
      justify-content: center;
      padding: ${theme.tokens.alias.space['space-m-16']}px;
    `}
  `}
`

export const StyledUnderConstructionInfoWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: ${theme.tokens.alias.space['space-s-12']}px;
    width: 100%;
  `}
`

export const StyledUnderConstructionTextWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: ${theme.tokens.alias.space['space-xxxs-4']}px;
  `}
`

export const StyledUnderConstructionPrimaryText = styled.div`
  ${typography['Body / 16 SemiBold']};
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.tokens.colors.text['text-primary']};
  `}
`

export const StyledUnderConstructionSecondaryText = styled.div`
  ${typography['Body / 14 Medium']};
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.tokens.colors.text['text-secondary-3']};
  `}
`
