import { FC } from 'react'

import { ContentLoader } from 'shared/ui/content-loader/content-loader'

import { StyledSportsWrapper } from './sport-tabs.styled'
import { SportsListProps } from './sport-tabs.types'

export const SportTabsLoader: FC<
  Pick<SportsListProps, 'leftOffset' | 'rightOffset' | 'className'>
> = ({ leftOffset = 16, rightOffset = 16, className }) => {
  return (
    <StyledSportsWrapper
      className={className}
      leftOffset={leftOffset}
      rightOffset={rightOffset}
    >
      <ContentLoader borderRadius={8} height={32} width={88} />
      <ContentLoader borderRadius={8} height={32} width={32} />
      <ContentLoader borderRadius={8} height={32} width={32} />
      <ContentLoader borderRadius={8} height={32} width={32} />
      <ContentLoader borderRadius={8} height={32} width={32} />
      <ContentLoader borderRadius={8} height={32} width={32} />
      <ContentLoader borderRadius={8} height={32} width={32} />
      <ContentLoader borderRadius={8} height={32} width={32} />
      <ContentLoader borderRadius={8} height={32} width={32} />
      <ContentLoader borderRadius={8} height={32} width={32} />
      <ContentLoader borderRadius={8} height={32} width={32} />
      <ContentLoader borderRadius={8} height={32} width={32} />
    </StyledSportsWrapper>
  )
}
