import { useSelector } from 'react-redux'
import { selectBetsHistoryPageValues } from 'astra-core/containers/BetsHistoryProvider'
import { useTranslation } from 'react-i18next'
import { Fragment, useMemo } from 'react'

import { BetHistoryCard } from 'features/bet-history-card'
import { IconTimeFilled40 } from 'shared/ui/Icon/General/IconTimeFilled40'
import {
  formatBetsHistoryDate,
  isTodayOrYesterday
} from 'shared/lib/format/bets-history-date'

import {
  EmptyContent,
  EmptyDescription,
  EmptyText,
  EmptyTitle,
  EmptyWrapper,
  ListWrapper,
  StyledBetsDate
} from './list.styled'
import { GroupedByDay } from './list.types'

const Empty = () => {
  const { t } = useTranslation()
  return (
    <EmptyWrapper>
      <EmptyContent>
        <IconTimeFilled40 colorToken="icon-secondary-3" size={40} />
        <EmptyText>
          <EmptyTitle>{t('history bets is empty')}</EmptyTitle>
          <EmptyDescription>
            {t('the bets will be displayed here')}
          </EmptyDescription>
        </EmptyText>
      </EmptyContent>
    </EmptyWrapper>
  )
}

export const HistoryList = () => {
  const betsListTableData = useSelector(selectBetsHistoryPageValues)
  const { t } = useTranslation()

  const betsListByDay: GroupedByDay[] = useMemo(() => {
    const groupedByDay: Record<string, GroupedByDay> = {}

    betsListTableData.forEach((obj) => {
      const date = new Date(obj.timestamp)
      const dayKey = formatBetsHistoryDate(date)

      if (!groupedByDay[dayKey]) {
        groupedByDay[dayKey] = {
          date: dayKey,
          bets: [],
          timestamp: date
        }
      }

      groupedByDay[dayKey].bets.push(obj)
    })

    return Object.values(groupedByDay)
  }, [betsListTableData])

  if (!betsListTableData.length) {
    return <Empty />
  }

  return (
    <ListWrapper>
      {betsListByDay.map(({ date, bets, timestamp }) => (
        <Fragment key={date}>
          <StyledBetsDate>
            <span>{isTodayOrYesterday(timestamp, t)}</span>
            {date}
          </StyledBetsDate>
          {bets.map((bet) => (
            <BetHistoryCard item={bet} key={bet.id} />
          ))}
        </Fragment>
      ))}
    </ListWrapper>
  )
}
