import { useState, useEffect } from 'react'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'
import duration from 'dayjs/plugin/duration'

dayjs.extend(duration)

export function useCountdown(expiryTime: string) {
  const [timeLeft, setTimeLeft] = useState<string>('00:00')

  useEffect(() => {
    function updateCountdown() {
      const now = dayjs()
      const expiryDate = dayjs(expiryTime)

      // Разница между текущим временем и временем окончания
      const diffInMilliseconds = expiryDate.diff(now)

      if (diffInMilliseconds <= 0) {
        setTimeLeft('00:00')
        return
      }

      // Используем dayjs.duration для вычисления минут и секунд
      const remainingDuration = dayjs.duration(diffInMilliseconds)
      const minutes = remainingDuration.minutes()
      const seconds = remainingDuration.seconds()

      setTimeLeft(remainingDuration.format('mm:ss'))
    }

    const intervalId = setInterval(updateCountdown, 1000)

    // Первоначальное вычисление времени
    updateCountdown()

    // Очистка таймера при размонтировании компонента
    return () => clearInterval(intervalId)
  }, [expiryTime])

  return timeLeft
}
