import React from 'react'

import { LayoutProfile } from 'layouts/profile'
import { LayoutPrimary } from 'layouts/primary'

import { TestCoin } from './test-coin'

export const PageTestCoin = () => (
  <LayoutPrimary>
    <LayoutProfile pageTitle={{ text: 'notifications' }} isHeaderFilled>
      <TestCoin />
    </LayoutProfile>
  </LayoutPrimary>
)

export default PageTestCoin
