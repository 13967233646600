import { FC } from 'react'
import { useField } from 'formik'

import { TextInputProps } from 'shared/ui/text-input/text-input.types'
import { TextInput } from 'shared/ui/text-input'
import { FieldProps } from 'features/formik/model/field'

export const TextInputFormik: FC<
  FieldProps<TextInputProps> & {
    preShowErrors?: boolean
    isAuthForm?: boolean
  }
> = ({ preShowErrors, ...props }) => {
  const [field, meta] = useField(props.name)

  return (
    <TextInput
      {...props}
      {...field}
      error={meta.error}
      touched={meta.touched}
    />
  )
}
