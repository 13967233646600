import { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'
import { BetOutcomeStatus } from 'betweb-openapi-axios'
import {
  ESportsCodes,
  getOutcomeTypeById,
  selectOutcomeCategoryById,
  selectSport
} from 'astra-core/containers/CommonDataProvider'
import { getBasketOutcomeName } from 'astra-core/utils/outcomes'
import { getBetOutcomeId, getEventTitle } from 'astra-core'
import { useTranslation } from 'react-i18next'

import { RootState } from 'shared/types/store'
import { Tooltip } from 'shared/ui/Tooltip'
import { useTextOverflow } from 'shared/lib/text-overflow/use-text-overflow'
import {
  IOutcomeItem,
  IOutcomes
} from 'features/bets-history-outcomes-table/outcomes-table.types'
import {
  OverflowableText,
  EventNameWrapper,
  StyledBetContentItem,
  StyledCellOutcome,
  TableHeadCell,
  HeaderTr,
  TableTBody
} from 'features/bets-history-outcomes-table/outcomes-table.styled'
import { OutcomeStatusIcon } from 'features/bets-history-outcomes-table/outcome-status-icon'
import { BasicStatistics } from 'features/bets-history-outcomes-table/basic-statistics'
import { SportIcon } from 'shared/ui/Icon/sport-icon'

import { OUTCOME_EVENT_DATE_FORMAT } from './outcomes-table.constants'

const DATE_COL_WIDTH = 116
const COEFF_COL_WIDTH = 54

const COEFF_ALIGN = 'end'

export const BetsHistoryHeader = () => {
  const { t } = useTranslation()

  return (
    <thead>
      <HeaderTr onClick={(e) => e.stopPropagation()}>
        <TableHeadCell colWidth={`${DATE_COL_WIDTH}px`}>
          {t('event beginning')}
        </TableHeadCell>
        <TableHeadCell>{t('event')}</TableHeadCell>
        <TableHeadCell>{t('outcome')}</TableHeadCell>
        <TableHeadCell
          colWidth={`${COEFF_COL_WIDTH}px`}
          textAlign={COEFF_ALIGN}
        >
          {t('coeff')}
        </TableHeadCell>
        <TableHeadCell colWidth="30%" largeColWidth="32%">
          {t('event result')}
        </TableHeadCell>
      </HeaderTr>
    </thead>
  )
}

export const BetsHistoryOutcomesTable: FC<IOutcomes> = ({ outcomes }) => {
  return (
    <TableTBody onClick={(e) => e.stopPropagation()}>
      {outcomes.map((outcome, index) => {
        const { event, outcomeTypeId, parameters, coefficient } = outcome

        const eventProbability = {
          eventId: event?.id || coefficient,
          outcomeTypeId,
          parameters
        }

        return (
          <OutcomeItem
            isLastItem={index === outcomes.length - 1}
            key={getBetOutcomeId(eventProbability)}
            outcome={outcome}
          />
        )
      })}
    </TableTBody>
  )
}

export const OutcomeItem: FC<IOutcomeItem> = ({ outcome, isLastItem }) => {
  const { live: isOutcomeOfLive, event, status } = outcome
  const { live: isLiveNow = false } = event || {}
  const [eventNameRef, isEventNameOverflowing] =
    useTextOverflow<HTMLSpanElement>()
  const [outcomeNameRef, isOutcomeNameOverflowing] =
    useTextOverflow<HTMLSpanElement>()

  const outcomeTypeData = useSelector((state: RootState) =>
    getOutcomeTypeById(state, outcome.outcomeTypeId)
  )
  const outcomeCategory = useSelector((state: RootState) =>
    selectOutcomeCategoryById(state, outcome.outcomeCategoryId)
  )

  const eventSport = useSelector((state) =>
    selectSport(state, outcome.event?.sportId || 0)
  )

  const outcomeType = useMemo(
    () =>
      getBasketOutcomeName({
        probWithOutcome: { ...outcome, outcomeTypeData: outcomeTypeData! },
        event: outcome.event!,
        isBetOutcome: true,
        outcomeCategory
      }),
    [outcome, outcomeCategory, outcomeTypeData]
  )

  const date = useMemo(
    () =>
      outcome.event?.eventDate &&
      dayjs(outcome.event?.eventDate).format(OUTCOME_EVENT_DATE_FORMAT),
    [outcome.event?.eventDate]
  )

  const isDisplayStatistic =
    isLiveNow ||
    isOutcomeOfLive ||
    (!isOutcomeOfLive && status === BetOutcomeStatus.Win)

  return (
    <StyledBetContentItem isLastItem={isLastItem}>
      <StyledCellOutcome fitContent>{date}</StyledCellOutcome>

      <StyledCellOutcome>
        {outcome.event && (
          <Tooltip
            isCommon={!isEventNameOverflowing}
            title={getEventTitle(outcome.event)}
          >
            <EventNameWrapper>
              <SportIcon
                numericSize={12}
                size="s"
                sport={eventSport?.code as ESportsCodes}
              />

              <OverflowableText ref={eventNameRef}>
                {getEventTitle(outcome.event, true)}
              </OverflowableText>
            </EventNameWrapper>
          </Tooltip>
        )}
      </StyledCellOutcome>

      <StyledCellOutcome>
        <Tooltip isCommon={!isOutcomeNameOverflowing} title={outcomeType}>
          <EventNameWrapper>
            <OutcomeStatusIcon status={outcome.status} />
            <OverflowableText ref={outcomeNameRef}>
              {outcomeType}
            </OverflowableText>
          </EventNameWrapper>
        </Tooltip>
      </StyledCellOutcome>

      <StyledCellOutcome textAlign={COEFF_ALIGN} fitContent>
        {outcome.coefficient}
      </StyledCellOutcome>

      <StyledCellOutcome>
        {isDisplayStatistic && event && <BasicStatistics event={event} />}
      </StyledCellOutcome>
    </StyledBetContentItem>
  )
}
