import React from 'react'

import { LayoutError } from 'layouts/error'
import { LayoutPrimary } from 'layouts/primary'

import { OfflinePage } from './OfflinePage/OfflinePage'

export const PageOffline = () => (
  <LayoutPrimary>
    <LayoutError>
      <OfflinePage />
    </LayoutError>
  </LayoutPrimary>
)

export default PageOffline
