import React from 'react'
import { useTranslation } from 'react-i18next'

import { UnderConstructionContent } from 'features/under-construction-content'
import { Button } from 'shared/ui/Button'

import { StyledUnderConstructionContainer } from './under-construction-page.styled'

export const UnderConstructionPage = () => {
  const [t] = useTranslation()

  const handleBackButton = () => {
    window.history.back()
  }

  return (
    <StyledUnderConstructionContainer>
      <UnderConstructionContent>
        <Button view="secondary-extra-large" onClick={handleBackButton}>
          {t('go back')}
        </Button>
      </UnderConstructionContent>
    </StyledUnderConstructionContainer>
  )
}
