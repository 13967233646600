import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  betsHistoryProviderActions,
  selectBetsHistoryCollapsedBets,
  selectBetsHistoryPageValues
} from 'astra-core/containers/BetsHistoryProvider'

import { IconChevronDoubleUp } from 'shared/ui/Icon/General/IconChevronDoubleUp'
import { IconButton } from 'shared/ui/buttons/IconButton'

export const ButtonToggleCollapse = () => {
  const { collapsedList } = useSelector(selectBetsHistoryCollapsedBets)
  const dispatch = useDispatch()

  const betsListTableData = useSelector(selectBetsHistoryPageValues)

  const isCollapsed = !!collapsedList.length

  const handleCollapse = useCallback(() => {
    if (isCollapsed) {
      collapsedList.forEach((id) => {
        dispatch(betsHistoryProviderActions.setCollapsedBets(id))
      })
    } else {
      betsListTableData
        .filter((bet) => !collapsedList.includes(bet.id))
        .forEach((bet) => {
          dispatch(betsHistoryProviderActions.setCollapsedBets(bet.id))
        })
    }
  }, [isCollapsed, betsListTableData, collapsedList, dispatch])

  return (
    <IconButton
      disabled={!betsListTableData.length}
      icon={IconChevronDoubleUp}
      twist={isCollapsed ? 180 : 0}
      view="outline-l"
      onClick={handleCollapse}
    />
  )
}
