import React, { FC } from 'react'

import { GlobalSearch } from 'widgets/global-search'
import { LayoutEventsTable } from 'layouts/events-table'
import { LayoutPrimary } from 'layouts/primary'

export const PageSearch: FC = () => (
  <LayoutPrimary>
    <LayoutEventsTable>
      <GlobalSearch />
    </LayoutEventsTable>
  </LayoutPrimary>
)

export default PageSearch
