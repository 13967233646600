import { useDispatch, useSelector } from 'react-redux'

import { useEventsPoll } from 'entities/event/api'

import { mainPageContainerActions } from './slice'
import { selectLiveEventsFilters } from './selectors'

export const useMainPageTopEventsData = () => {
  const dispatch = useDispatch()

  useEventsPoll({
    fetch: () => {
      dispatch(mainPageContainerActions.fetchTopEvents())
    },
    pollingInterval: 5000
  })
}

export const useMainPageLiveEventsData = () => {
  const dispatch = useDispatch()
  const { isTop } = useSelector(selectLiveEventsFilters)

  useEventsPoll({
    fetch: () => {
      dispatch(mainPageContainerActions.fetchLiveEvents())
    },
    top: isTop,
    pollingInterval: 5000
  })
}
