import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { selectAccountUser } from 'astra-core/containers/AccountProvider'
import { useSelector } from 'react-redux'

import { ModalRef } from 'shared/ui/modal/modal.types'
import { Modal } from 'shared/ui/modal'
import { Typography } from 'shared/ui/typography'
import { IconWarningTriangle } from 'shared/ui/Icon/General/IconWarningTriangle'
import { Button } from 'shared/ui/Button'
import { smsApi } from 'entities/legacy-api/lib/sms'
import { TextInput } from 'shared/ui/text-input'
import { formatPhoneNumber } from 'shared/lib/format/format-phone'
import { responsibleGamblingApi } from 'entities/legacy-api/lib/responsible-gambling'
import { LoaderLogo } from 'shared/ui/LoaderLogo'
import {
  ENotificationContainerIds,
  usePopUpNotification
} from 'shared/lib/Toastify'

import {
  StyledLoaderWrapper,
  StyledModalBottom,
  StyledModalIconWrapper,
  StyledModalInputBlock,
  StyledModalSMS,
  StyledModalWarning
} from './ConfirmModal.styled'
import { EConfirmView, IConfirmModal } from './lib/types'
import { NotificationSuccess, TimeLeft } from './ui'

const { useSendCodeMutation } = smsApi
const { usePostLimitMutation, usePostBreakMutation, useGetStatusQuery } =
  responsibleGamblingApi

export const ConfirmModal: FC<PropsWithChildren<IConfirmModal>> = ({
  view,
  children,
  sum,
  period
}) => {
  const [t] = useTranslation()

  const modalRef = useRef<ModalRef>(null)

  const handleCloseModal = useCallback(() => {
    modalRef.current?.close()
  }, [])

  const [code, setCode] = useState('')
  const user = useSelector(selectAccountUser)!

  const [
    postLimit,
    {
      data: dataLimit,
      isLoading: isLimitLoading,
      isSuccess: isLimitSuccess,
      error: limitError
    }
  ] = usePostLimitMutation()

  const [
    postBreak,
    {
      data: dataBreak,
      isLoading: isBreakLoading,
      isSuccess: isBreakSuccess,
      error: breakError
    }
  ] = usePostBreakMutation()

  const [
    sendCode,
    { data: dataSend, isLoading: isSendLoading, isSuccess: isCodeSuccess }
  ] = useSendCodeMutation()

  const { refetch } = useGetStatusQuery({})

  const SMSMessageId = localStorage.getItem('SMS_message_id')
  const [SMSts, setSMSts] = useState(localStorage.getItem('SMS_ts'))

  const handleOpenModal = useCallback(() => {
    if ((!sum && view === EConfirmView.LIMIT) || !user.phone) return

    if (SMSts === null) {
      sendCode({ phone: user.phone })
    } else if (new Date(SMSts) < new Date()) {
      sendCode({ phone: user.phone })
    }

    modalRef.current?.open()
  }, [sum, view, SMSts, sendCode, user.phone])

  useEffect(() => {
    if (isCodeSuccess && dataSend?.data) {
      const date = new Date(+dataSend?.data.ts * 1000).toString()
      localStorage.setItem('SMS_ts', date)
      setSMSts(date)

      localStorage.setItem('SMS_message_id', dataSend?.data.message_id)
    }
  }, [isCodeSuccess, dataSend?.data])

  const notifySuccess = usePopUpNotification({
    content: {
      children: (props) => <NotificationSuccess {...props} />
    },
    options: {
      containerId: ENotificationContainerIds.SYSTEM,
      autoClose: 5000
    }
  })

  useEffect(() => {
    if (isBreakSuccess || isLimitSuccess) {
      refetch()
      notifySuccess()
      modalRef.current?.close()
    }
  }, [isLimitSuccess, isBreakSuccess, refetch])

  const handleSubmit = useCallback(() => {
    if (view === EConfirmView.LIMIT && SMSMessageId && period && sum) {
      postLimit({
        period,
        sum,
        code: +code,
        message_id: SMSMessageId
      })
    }

    if (view === EConfirmView.BREAK && SMSMessageId && period) {
      postBreak({
        period,
        code: +code,
        message_id: SMSMessageId
      })
    }
  }, [sum, period, code, SMSMessageId, postBreak, postLimit, view])

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault()
            handleOpenModal()
          }
        }}
        onClick={handleOpenModal}
      >
        {children}
      </div>

      <Modal
        title={t(
          view === EConfirmView.BREAK ? 'break approve' : 'limit approve'
        )}
        ref={modalRef}
      >
        <>
          <StyledModalSMS>
            {user.phone && (
              <Typography color="text-primary-2" font="Body / 14 Medium">
                <Trans
                  components={{
                    bold: (
                      <Typography
                        color="text-primary-2"
                        font="Body / 14 Bold"
                      />
                    )
                  }}
                  i18nKey="enter the verification code"
                  values={{ phone: formatPhoneNumber(user.phone) }}
                />
              </Typography>
            )}

            <StyledModalWarning>
              <StyledModalIconWrapper>
                <IconWarningTriangle colorToken="icon-warning" />
              </StyledModalIconWrapper>

              <Typography color="text-primary-2" font="Body / 14 Medium">
                {t('employees will not be able to cancel the limit you set')}
              </Typography>
            </StyledModalWarning>

            <StyledModalInputBlock>
              <TextInput
                errorMessage={
                  limitError || breakError
                    ? 'code entered is incorrect or outdated'
                    : ''
                }
                label="code from sms"
                value={code}
                touched
                onChange={(e) => setCode(e.target.value)}
              />

              {user.phone && (
                <TimeLeft
                  sendCode={() => sendCode({ phone: user.phone! })}
                  SMSts={SMSts || ''}
                />
              )}
            </StyledModalInputBlock>

            {isSendLoading && (
              <StyledLoaderWrapper>
                <LoaderLogo />
              </StyledLoaderWrapper>
            )}
          </StyledModalSMS>

          <StyledModalBottom>
            <Button view="secondary-extra-large" onClick={handleCloseModal}>
              {t('cancel')}
            </Button>
            <Button
              isLoading={isBreakLoading || isLimitLoading}
              view="primary-extra-large"
              onClick={handleSubmit}
            >
              {t('confirm')}
            </Button>
          </StyledModalBottom>
        </>
      </Modal>
    </>
  )
}
