import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  resultsProviderActions,
  selectFetchItemsResults
} from 'astra-core/containers/ResultsProvider'

import { Skeleton } from 'shared/ui/skeleton'

import { StyledResultsWrapper } from './results.styled'
import { ResultChampionships } from './components/result-championships'
import { SportResultsLoader } from './result.loader'

export const Results = () => {
  const dispatch = useDispatch()

  const isLoadingResults = useSelector(selectFetchItemsResults)

  useEffect(() => {
    dispatch(resultsProviderActions.fetchResults({ withLinkedEvents: false }))
  }, [dispatch])

  return (
    <StyledResultsWrapper>
      <Skeleton
        isLoading={!!isLoadingResults}
        styledFallback={<SportResultsLoader />}
      >
        <ResultChampionships />
      </Skeleton>
    </StyledResultsWrapper>
  )
}
