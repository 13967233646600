import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectLoyaltyCouponCreatedStatus } from 'astra-core/containers/BasketProvider'
import { selectLoyaltyLoading } from 'astra-core/containers/AccountProvider'
import { useWindowWidth } from '@react-hook/window-size'

import { BonusCard } from 'shared/ui/promo-and-bonus-card'
import { LoaderLogo } from 'shared/ui/LoaderLogo'
import { getColumnsCount } from 'widgets/my-bonuses/constants'

import {
  StyledMyBonusesContent,
  StyledMyBonusesContentWrapper
} from '../../my-bonuses.styled'
import { MyBonusesEmptyContent } from '../my-bonuses-empty-content'

export const MyBonusesContent = () => {
  const bonusCoupons = useSelector(selectLoyaltyCouponCreatedStatus)
  const loading = useSelector(selectLoyaltyLoading)
  const onlyWidth = useWindowWidth()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const timeout = setTimeout(() => setIsLoading(loading), 500)

    return () => clearTimeout(timeout)
  }, [loading])

  const columns = useMemo(() => getColumnsCount(onlyWidth), [onlyWidth])

  if (isLoading) return <LoaderLogo />

  if (!bonusCoupons.length && !isLoading) return <MyBonusesEmptyContent />

  return (
    <StyledMyBonusesContent>
      <StyledMyBonusesContentWrapper>
        {bonusCoupons.map((bonusCoupon, index) => {
          const isLastInRow = (index + 1) % columns === 0

          return (
            <BonusCard
              bonusCoupon={bonusCoupon}
              isLastInRow={isLastInRow}
              key={bonusCoupon.id}
            />
          )
        })}
      </StyledMyBonusesContentWrapper>
    </StyledMyBonusesContent>
  )
}
