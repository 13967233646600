import React from 'react'
import { useGameVendorSettings } from 'astra-core'
import { VirtualGameVendor } from 'betweb-openapi-axios'

import { AvailablePanel } from 'shared/ui/AvailablePanel'
import { TurboGamesGame } from 'pages/page-games-turbo/TurboGames/components'
import { LayoutGame } from 'layouts/game'
import { LayoutPrimary } from 'layouts/primary'

export const PageGamesTurboGame = () => {
  const isAvailable = useGameVendorSettings(VirtualGameVendor.TurboGames)

  return (
    <LayoutPrimary>
      <LayoutGame>
        {isAvailable ? <TurboGamesGame /> : <AvailablePanel />}
      </LayoutGame>
    </LayoutPrimary>
  )
}

export default PageGamesTurboGame
