import React, { FC, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { selectConfig } from 'astra-core/containers/ConfigProvider'
import {
  accountProviderActions,
  selectAccountId,
  selectAccountUser,
  selectFetchTurboGamesTokenRequest,
  selectFetchUserRequest,
  selectTurboGamesToken
} from 'astra-core/containers/AccountProvider'
import { selectAccountCurrencyData } from 'astra-core/containers/CommonDataProvider'

import { LoaderPoints } from 'shared/ui/LoaderPoints'
import { StyledLoaderPointsWrapper } from 'pages/page-games-turbo/TurboGames/components/TurboGamesGame/TurboGamesGame.styled'

import { getIframeData } from './constants'

export const TurboGamesGame: FC = () => {
  const dispatch = useDispatch()
  const user = useSelector(selectAccountUser)
  const accountId = useSelector(selectAccountId)
  const { loading: fetchUserLoading } = useSelector(selectFetchUserRequest)
  const { loading: fetchBetGamesTokenLoading } = useSelector(
    selectFetchTurboGamesTokenRequest
  )

  useEffect(() => {
    if (accountId) {
      dispatch(
        accountProviderActions.getTurboGamesToken({
          accountId
        })
      )
    }

    return () => {
      dispatch(accountProviderActions.resetTurboGames())
    }
  }, [accountId, dispatch])

  const loaded =
    fetchUserLoading === false && (!user || fetchBetGamesTokenLoading === false)

  return loaded ? (
    <TurboGamesIframe />
  ) : (
    <StyledLoaderPointsWrapper>
      <LoaderPoints />
    </StyledLoaderPointsWrapper>
  )
}

const TurboGamesIframe = () => {
  const { token: turboGamesToken } = useSelector(selectTurboGamesToken) || {}
  const { id: userId } = useSelector(selectAccountUser) || {}
  const { code: currencyName } = useSelector(selectAccountCurrencyData) || {}
  const config = useSelector(selectConfig)
  const { gameNameId } = useParams<{ gameNameId: string }>()

  const iframeData = useMemo(
    () =>
      getIframeData({
        srcData: {
          currencyName,
          gameNameId,
          userId,
          token: turboGamesToken,
          launchUrl: config.TURBOGAMES_LAUNCH,
          demoLaunchUrl: config.TURBOGAMES_LAUNCH_DEMO,
          operator: config.TURBOGAMES_OPERATOR
        }
      }),
    [
      config.TURBOGAMES_LAUNCH,
      config.TURBOGAMES_LAUNCH_DEMO,
      config.TURBOGAMES_OPERATOR,
      currencyName,
      gameNameId,
      turboGamesToken,
      userId
    ]
  )

  return (
    <iframe
      src={iframeData.src}
      style={iframeData.styles}
      title={iframeData.id}
    />
  )
}
